.menu_sublist
{
    display: none;
}

.left_menu
{
    &.menu
    {
        font-size: 16px;
        color:     $gray-dark;

        .menu_item
        {
            margin-bottom: 15px;
        }

        .menu_list
        {
            @include media('desctop-grid')
            {
                padding-left: 47px;
            }

        }

        .menu_item-title
        {
            @extend %std-transition;

            cursor:      pointer;

            line-height: 20px;

            &:hover, &.active
            {
                color: $orange;
            }
        }

        .menu_sublist
        {

        }

        .menu_sublink
        {
            @extend %std-transition;

            position:     relative;
            display:      block;

            padding-left: 30px;

            line-height:  22px;

            &:hover, &.active
            {
                color: $orange;

            }

            &:hover
            {
                &:before
                {
                    background: $orange;
                }
            }

            &:before
            {
                @extend %std-transition;

                content:    "";
                position:   absolute;
                top:        8px;
                left:       0;

                width:      22px;
                height:     2px;
                background: $gray-dark;

            }
        }
    }
}