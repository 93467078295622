.equipment-preview
{
    &.item
    {
        @extend %std-transition;

        position:        relative;
        top:             0;

        display:         flex;
        align-items:     center;
        flex-direction:  column;

        justify-content: center;

        color:           $gray-dark;

        height:          275px;

        border:          2px solid $gray-lightest;

        @include media('desctop-grid')
        {
            border: 2px solid $gray-lightest;

            height: 265px;

            &:hover
            {
                top:        -10px;

                border:     2px solid $orange;

                box-shadow: 0 20px 30px 0 rgba(29, 29, 27, 0.15);

                & .item_go
                {
                    top:     70%;
                    opacity: 1;
                }

                & .item_title
                {
                    top: -15px;
                }
            }

        }

        @include media('d-wxgap')
        {
            height: 315px;
        }

        @include media('d-fhd')
        {
            height: 420px;
        }
    }

    .item_block
    {

        position:        relative;

        width:           100%;

        display:         flex;
        align-items:     center;
        justify-content: center;
        flex-direction:  column;

        overflow:        hidden;

        &:nth-child(1)
        {
            flex: 1;
        }

        &:nth-child(2)
        {
            height: 70px;

            @include media('desctop-grid')
            {
                height: 100px;
            }
        }
    }

    .item_image-container
    {
        position:            absolute;
        right:               0;
        left:                0;
        bottom:              0;

        background-size:     contain;
        background-position: center;
        background-repeat:   no-repeat;

        top:                 28px;

        @include media('desctop-grid')
        {
            top: 20px;
        }

        @include media('d-wxgap')
        {
            top: 25px;
        }
    }

    .item_title
    {
        @extend %std-transition;

        position:   relative;
        top:        0;

        text-align: center;

        font-size:  20px;
    }

    .item_go
    {
        position:  absolute;
        top:       50%;
        transform: translateY(-50%);
        left:      0;
        right:     0;
        opacity:   0;

        display:   none;

        @include media('t-ipad')
        {
            display: flex;
            width:   100%;
        }

        @extend %go-button-without-background;
    }
}