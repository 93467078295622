.project_description-container
{
  background-color: $blue-light;
  color: #ffffff;
}

.project_description
{
  @include grid-padding();
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
  
  @include media($tu)
  {
    width: 480px;
    max-width: 100%;
    padding: $gutter-width * 2;
    
    
  }
  
}