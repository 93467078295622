// Font sizes



%font-title-1
{

    font-size:   19px;
    line-height: 30px;
    font-weight: 400;

    @include media('t-ipad')
    {
        font-size:   28px;
        line-height: 39px;
    }
}

%font-title-2
{
    font-size:   18px;
    font-weight: 400;

}

%font-title-3
{
    font-size:   10px;
    line-height: 14px;
    font-weight: 700;
}

%font-title-4
{
    font-size:   15px;
    line-height: 21px;
    font-weight: 400;
}

%font-small
{
    font-size:   10px;
    line-height: 16px;
    font-weight: 400;
}

%main-font
{
    font-family: 'BebasNeue';
    font-weight: 700;
}

%main-second-font
{
    font-family: 'BebasNeue';
    font-weight: 400;
}

%other-font
{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}