//.projects-slider/
.projects-slider
{
  margin: 20px 0;
  
  @include media($tu)
  {
    margin: 30px 0;
  }
  //.projects-slider.swiper-container
  &.swiper-container
  {
    //overflow: visible;
    
  }
}

//.projects-slider_wrapper/
.projects-slider_container
{
  @include grid-container-variable(1350px);
  position: relative;
  margin-bottom: 13px;
  
  @include media($tu)
  {
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }
  @include media($du)
  {
    padding-left: $gutter-width * 2;
    padding-right: $gutter-width * 2;
  }
  
}

//.projects-slide
.projects-slide
{
  max-width: calc(100% - 40px);
  max-width: -webkit-calc(100% - 40px);
  //max-width:  88%;
  transition: all linear .3s;
  
  @include media($tu)
  {
    max-width: 36%;
  }
  //.projects-slide.swiper-slide-active, .projects-slide.swiper-slide-duplicate-active
  &.swiper-slide-active, &.swiper-slide-duplicate-active
  {
    @extend %project-shadow;
    
  }
}

