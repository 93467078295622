@mixin go-button($span-to-left, $svg-to-right, $padding:12px 56px) {
    @extend %easy-out-transition;

    position:   relative;

    display:    inline-block;

    font-size:  16px;

    text-align: center;

    border:     2px solid $orange;

    padding:    $padding;
    cursor:     pointer;
    background:    none;

    @include media('t-ipad')
    {
        &:hover
        {
            background: $orange;

            & span
            {

                left:       $span-to-left;

                transition: all linear 0.2s;
            }

            & svg
            {

                right:      $svg-to-right;

                opacity:    1;

                transition: all linear 0.2s;
            }
        }
    }

    span
    {
        @extend %easy-out-transition;
        font-family: 'BebasNeue';
        font-weight: 700;

        position:    relative;
        left:        0;

        line-height: 15px;
        padding-top: 2px;
    }

    svg
    {
        @extend %easy-out-transition;

        position: absolute;
        right:    0;
        top:      1px;

        display:  none;

        width:    25px;
        height:   15px;

        fill:     $gray-lightest;

        opacity:  0;

        @include media('t-ipad')
        {
            display: block;

        }
    }
}