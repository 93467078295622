
body:not(.page_home) .header
{
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter:         blur(4px);
}

.header
{

    @extend %std-transition;

    position:   fixed;
    top:        0;
    left:       0;
    width:      100%;
    z-index:    100;
    background: rgba(0, 0, 0, 0.97);

    // start styles on white background

    &.md_back-light
    {
        background: rgba(255, 255, 255, 0.97);

        .logo .logo_icon-text
        {
            fill: $gray-dark;
        }

        .logo
        {
            &:after
            {
                color: $gray-dark;
            }
        }

        .hamburger
        {
            .hamburger-line
            {
                background: $gray-dark;

                &:before, &:after
                {
                    background: $gray-dark;
                }
            }
        }

        .header_menu
        {
            @include media('desctop-grid-down')
            {
                background: rgba(255, 255, 255, 0.97);
            }

            .menu_list
            {
                border-color: $gray-lightest;
            }

            .menu_item
            {
                &.active
                {
                    & .menu_link
                    {
                        color: $orange;
                    }
                }
            }

            .menu_link
            {
                color: $gray-dark;

                &:hover
                {
                    color: $orange;
                }

            }
        }

    }

    // end styles on white background

    &.active
    {
        position:   fixed;
        background: none;
    }

    @include media('t-ipad')
    {
        position: fixed;
    }

    .header_top
    {
        display:     flex;
        align-items: flex-start;

        padding:     20px;

        @include media('desctop-grid')
        {
            padding: 40px 0 40px 40px;
        }
    }

    .logo
    {
        position: relative;
        z-index:  9999;

        &:after
        {
            @extend %std-transition;

            content:   "©";
            position:  absolute;
            right:     -11px;
            font-size: 12px;
            top:       8px;
            color:     $gray-lightest;
        }

        &.swiper-color-dark
        {
            &:after
            {
                color: $gray-dark;
            }

            &.js-fill-light
            {
                &:after
                {
                    color: $gray-lightest;
                }
            }
        }

        .logo_icon-image
        {
            width:  40px;
            height: 40px;

            fill:   $orange;
        }

        .logo_icon-text
        {

            @extend %std-transition;

            display:     block;
            position:    relative;

            width:       107px;
            height:      25px;

            fill:        $gray-lightest;

            margin-left: 8px;

            &.swiper-color-dark
            {
                fill: $gray-dark;

                &.js-fill-light
                {
                    fill: $gray-lightest;
                }
            }
        }

        .logo_link
        {
            display:     flex;
            align-items: flex-end;
        }

    }

    .header_menu
    {
        @extend %std-transition;

        flex:       1;

        position:   absolute;
        left:       0;
        right:      0;
        top:        0;

        z-index:    999;

        overflow:   hidden;

        height:     0;

        background: rgba(0, 0, 0, 0.95);

        @include media('desctop-grid')
        {
            position:    relative;
            height:      auto;

            background:  none;

            padding-top: 13px;

            text-align:  right;

            overflow:    visible;
        }
    }

    .menu
    {

        .menu_list
        {
            display:         flex;
            align-items:     flex-start;
            justify-content: flex-end;

            flex-direction:  column;

            padding:         80px 0 0 0;

            @include media('desctop-grid')
            {
                display:        inline-flex;
                flex-direction: row;

                padding:        0;

                border-top:     2px solid rgba(255, 255, 255, 0.2);

                &.swiper-color-dark
                {
                    border-color: $gray-lightest;

                    .menu_item
                    {
                        &.active
                        {
                            & .menu_link
                            {
                                color: $orange;
                            }
                        }
                    }

                    .menu_link
                    {
                        color: $gray-dark;

                        &:hover
                        {
                            color: $orange;
                        }

                    }
                }
            }

        }

        .menu_item
        {

            position:   relative;
            width:      100%;

            text-align: right;

            font-size:  0;

            &.active
            {
                & .menu_link
                {
                    color: $orange;
                }

            }

            @include media('desctop-grid')
            {

                &:last-child
                {
                    & .menu_link
                    {
                        padding-right: 30px;
                    }
                }

                &.active
                {
                    &:before
                    {
                        content:    '';
                        position:   absolute;

                        left:       0;
                        right:      0;
                        top:        -3px;

                        height:     4px;
                        background: $orange;
                    }
                }

                width:      auto;

                text-align: center;
            }
        }

        .menu_link
        {
            @extend %std-transition;

            color:     $gray-lightest;

            font-size: 20px;

            padding:   20px;

            @include media('desctop-grid')
            {
                padding:     16px 10px 0 10px;

                font-size:   16px;

                line-height: 13px;

                &:hover
                {
                    color: $orange;
                }
            }
        }
    }

}