.home-screen-3
{
    position:   relative;

    background: $blue;

    @include media('t-ipad')
    {
        height: 100%;
    }

    &.md_color-light
    {
        color: $gray-lightest;
    }

    .section_back
    {
        position:            absolute;
        top:                 0;
        bottom:              0;
        right:               0;
        left:                0;

        background-image:    url("/../img/home-screen-3-back.jpg");
        background-size:     cover;
        background-position: center top;

        opacity:             0.4;
    }

    .section_content
    {
        position:        relative;
        z-index:         1;

        display:         flex;
        align-items:     flex-start;
        justify-content: center;
        flex-direction:  column;

        height:          100%;
    }

    .section_titles
    {
        display:         flex;
        flex:            1;
        flex-direction:  column;
        justify-content: flex-end;

        padding:         60px 20px 60px 20px;

        @include media('t-ipad')
        {
            height:          auto;
            justify-content: center;
            padding:         40px 20px 60px 20px;
        }

        @include media('desctop-grid')
        {

            padding: 100px 80px 40px 40px;
        }
    }

    .section_title
    {
        @extend %section_title;

        @extend %titles-shadow;
    }

    .section_subtitle
    {
        @extend %section_subtitle;

        @extend %titles-shadow;
    }

    .section_description
    {
        @extend %section_description;

        margin: 5px 0 20px 0;

        &:before
        {
            background: $gray-lightest;
        }
    }

    .section_link
    {
        @include go-button(-20px, -35px);

        margin-left: 30px;

        @include media('desctop-grid')
        {
            margin-left: 45px;
        }
    }
}

