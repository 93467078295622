@import "home/home-screen-1";
@import "home/home-screen-2";
@import "home/home-screen-3";

.page_home
{

    @include media('t-ipad')
    {
        overflow: hidden;

        height:   100%;
    }

    .header
    {
        @include media('t-ipad')
        {
            background: none;
        }

    }
}

.big-vertical-swiper
{
    height:   100%;

    overflow: visible;

    @include media('desctop-grid')
    {
        overflow: hidden;
    }

    .home-swiper-wrapper
    {

        display: block;

        @include media('desctop-grid')
        {
            display: flex;
        }
    }

    .swiper-pagination.vertical-pagination
    {
        display:       none;

        right:         25px;

        @include media('t-ipad')
        {
            display: block;

            &.swiper-color-dark
            {
                .swiper-pagination-bullet
                {
                    &:before
                    {
                        color: $gray-dark;
                    }

                    &.swiper-pagination-bullet-active
                    {
                        &:before
                        {
                            color: $white;
                        }

                    }
                }
            }
        }

        counter-reset: item-paginate;

        & .swiper-pagination-bullet
        {

            @extend %quick-transition;

            position:      relative;

            width:         25px;
            height:        25px;

            background:    none;

            opacity:       1;

            border-radius: 0;

            margin:        10px 0;

            &:before
            {
                @extend %std-transition;

                content:           counter(item-paginate);
                counter-increment: item-paginate;

                position:          absolute;
                top:               8px;
                left:              9px;

                font-size:         16px;
                line-height:       12px;

                color:             $white;
            }

            &:hover:not(.swiper-pagination-bullet-active)
            {
                &:before
                {
                    color: $orange;
                }

            }
        }

        & .swiper-pagination-bullet-active
        {
            background: $orange;
        }

    }

}