#project_page
{
    padding-bottom: 35px;
    @include media($tu)
    {
        padding-bottom: 54px;
    }
}

.project
{

    //.project_title/
    &_title
    {
        font-size:   42px;
        line-height: 1.1;

        @include media($du)
        {
            font-size:     54px;
            line-height:   1;
            margin-bottom: 2px;
        }

        //.project_title-description/

        &-description
        {
            font-family:    $font-secondary;
            font-weight:    normal;

            line-height:    1.8;
            font-size:      14px;
            text-transform: uppercase;
            margin-bottom:  20px;

            @include media($du)
            {
                margin-bottom: 16px;
            }

        }
    }
    //.project_content/
    &_content
    {
        font-size:   16px;
        line-height: 1.4;
        font-family: $font-secondary;
        font-weight: normal;

    }
    //.project_pagination/
    &_pagination
    {
        display: none;

        @include media($tu)
        {
            display:    flex;
            margin-top: $gutter-width * 2;
        }
    }

    //.project_top/
    &_top
    {
        @extend %clearfix;

        position:   relative;

        margin-top: $gutter-width;
        @include media($tu)
        {

            display:       flex;
            margin-top:    $gutter-width * 2;
            margin-bottom: 27px;

        }
    }

    //.project_container/
    &_container
    {
        position: relative;
        @include media($tu)
        {
            align-self: stretch;
            width:      100%;
        }

        .project_alone-slide
        {
            position:            absolute;
            left:                0;
            right:               0;
            bottom:              0;
            top:                 0;

            background-size:     cover;
            background-position: center;
            background-repeat:   no-repeat;
        }

        //.project_container.mod_left/
        &.mod_left
        {

            @include media($tu)
            {
                width: 50%;
                flex:  1 50%;
            }

            @include media('desctop-grid')
            {
                width: 70%;
                flex:  1 70%;
            }
        }
        //.project_container.mod_right/
        &.mod_right
        {
            @include media($tu)
            {
                width: 50%;
            }

            @include media('desctop-grid')
            {
                display:         flex;
                flex-direction:  column;
                justify-content: space-between;
                width:           480px;
                min-height:      600px;
            }

        }

    }
}

@import "project/slider",
"project/description";
