.addtocalendar
{
    margin-top: 10px;
    border:     none !important;

    .atcb-list
    {
        width:      auto;
        left:       -2px;
        right:      -2px;
        top:        43px;

        background: none;

        .atcb-item
        {
            background: none;
            text-align: center;
        }

        .atcb-item-link
        {
            padding: 10px;

            &:hover
            {
                background: $orange;
            }
        }
    }
}

.meeting .order_response .js-order_close
{
    margin-top: 16px;
}