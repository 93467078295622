//.projects-preview/
.projects-previews
{

    //.projects-previews_list/
    &_list
    {
        @include grid-container-variable(1340px);
        position:    relative;
        z-index:     1;
        padding-top: $gutter-width;

        @include media($tu)
        {
            font-size: 0;
        }
        @include media($du)
        {
            padding: 15px $gutter-width $gutter-width $gutter-width;
        }
    }

    //.projects-previews_li/
    &_li
    {
        @include grid-padding();
        padding-bottom: $gutter-width;

        &:nth-child(n + 5)
        {

            @include media($td)
            {
                display: none;
            }
        }
        @include media($tu)
        {
            display:        inline-block;
            vertical-align: top;
            width:          percentage(1/3);
        }
        @include media($du)
        {

            width:          percentage(1/5);
            padding-bottom: $gutter-width*2;

        }
        &.visible
        {

            @include media($dd)
            {
                display: block;
            }
        }
    }

    //.projects-previews_show-more/
    &_show-more
    {
        @extend %btn-reset-default;
        @extend %main-font;

        display:        table;
        margin:         4px auto 24px;

        text-transform: uppercase;

        color:          $orange;

        font-size:      16px;
        font-weight:    700;

        @include media($tu)
        {
            display: none;
        }
        &.disabled
        {
            color:   $gray-light;
            display: none;
        }
    }
    //.projects-previews_show-more-arrow/
    &_show-more-arrow
    {
        width:            7px;
        height:           7px;
        margin-right:     3px;

        transform:        rotateZ(-90deg);
        -moz-transform:   rotateZ(-45deg);
        transform-origin: center center;

        fill:             $orange;
        display:          inline-block;
        vertical-align:   middle;
        @at-root .projects-previews_show-more.disabled
        {
            fill: $gray-light;
        }

    }
}
