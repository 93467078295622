%btn-reset-default
{
  background-color: transparent;
  -webkit-appearance: none;
  border: none;
  outline: none;
}


%bg-base
{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
