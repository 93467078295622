%content
{

    padding-left:  20px;
    padding-right: 20px;

    @include media('desctop-grid')
    {
        padding-left:  40px;
        padding-right: 40px;
    }
}

%std-transition
{
    transition: all linear 0.2s;
}

%long-transition
{
    transition: all linear 0.4s;
}

%quick-transition
{
    transition: all linear 0.1s;
}

%easy-in-transition
{
    transition: all ease-in 0.4s;
}

%easy-out-transition
{
    transition: all ease-out 0.4s;
}

%go-button-without-background
{
    @extend %std-transition;

    position:        relative;

    display:         inline-flex;
    align-items:     center;
    justify-content: center;

    align-self:      flex-start;

    font-size:       16px;

    span
    {
        @extend %std-transition;

        position:    relative;
        left:        0;

        line-height: 15px;
        padding-top: 2px;

        color:       $orange;
    }

    svg
    {
        @extend %std-transition;

        position:    relative;

        width:       25px;
        height:      15px;

        fill:        $orange;

        margin-left: 5px;

    }
}

%section_title-big
{
    font-size: 24px;

    @include media('desctop-grid')
    {
        font-size: 44px;
    }

    @include media('d-wxgap')
    {
        font-size: 72px;
    }
}

%section_title
{
    font-size: 24px;
    color:     $orange;

    @include media('desctop-grid')
    {
        font-size: 44px;
    }

    @include media('d-wxgap')
    {
        font-size: 54px;
    }
}

%section_subtitle
{
    font-size:   34px;
    line-height: 32px;
    color:       $gray-lightest;

    @include media('desctop-grid')
    {
        font-size:   54px;
        line-height: normal;
    }

    @include media('d-wxgap')
    {
        font-size: 72px;
    }
}

%description-padding-schema
{
    padding-left: 30px;

    @include media('desctop-grid')
    {
        padding-left: 45px;
    }
}

%section_description
{
    @extend %other-font;
    @extend %description-padding-schema;

    position:    relative;

    font-size:   12px;
    line-height: 15px;

    max-width:   1010px;
    width:       100%;

    color:       #d4cfcd;

    &:before
    {
        content:    "";
        position:   absolute;
        left:       0;
        top:        6px;

        width:      20px;
        height:     2px;

        background: #d4cfcd;
    }

    @include media('desctop-grid')
    {
        font-size:   16px;
        line-height: 22px;

        &:before
        {
            width: 30px;

            top:   9px;
        }
    }

    @include media('d-wxgap')
    {
        line-height: 25px;

        &:before
        {
            top: 10px;
        }
    }
}

%arrow-down
{
    position:  absolute;
    bottom:    10px;
    z-index:   9;
    left:      50%;
    transform: translateX(-50%);

    padding:   10px;

    cursor:    pointer;

    @include media('desctop-grid')
    {
        bottom: 25px;

        &:hover
        {
            svg
            {
                fill: $orange;
            }
        }
    }

    svg
    {
        @extend %std-transition;

        width:  20px;
        height: 10px;
    }
}

%arrow-top
{
    position:  absolute;
    bottom:    10px;
    z-index:   9;
    left:      50%;
    transform: translateX(-50%);

    padding:   10px;

    cursor:    pointer;

    @include media('desctop-grid')
    {
        bottom: 25px;

        &:hover
        {
            svg
            {
                fill: $orange;
            }
        }
    }

    svg
    {
        @extend %std-transition;

        width:     20px;
        height:    10px;

        transform: rotateX(180deg);
    }
}

%screen-container
{
    display:        flex;
    flex-direction: column;

    width:          100%;

    min-height:     100vh;
}

%titles-shadow
{
    text-shadow: 0 10px 15px rgba(0, 0, 0, 0.5), 1px 1px 4px rgba(0, 0, 0, 0.7);
}