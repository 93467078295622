.hamburger
{

    position:         absolute;
    right:            10px;
    top:              28px;
    z-index:          9999;

    padding:          10px;

    cursor:           pointer;

    border:           none;
    background-color: transparent;

    overflow:         hidden;

    @include media('desctop-grid')
    {
        display: none;
    }

    &.swiper-color-dark
    {
        .hamburger-line
        {
            background: $gray-dark;

            &:before, &:after
            {
                background: $gray-dark;
            }
        }

        &.hamburger-color-light
        {
            .hamburger-line
            {
                background: transparent;

                &:before, &:after
                {
                    background: $gray-lightest;
                }
            }
        }

    }

    &.active
    {
        .hamburger-line
        {
            background: none !important;

            &:before
            {
                width:            100%;
                top:              0;
                transform-origin: center;
                transform:        rotate(45deg);
            }

            &:after
            {
                width:            100%;
                top:              0;
                transform-origin: center;
                transform:        rotate(-45deg);
            }
        }
    }
}

.hamburger-box
{
    position: relative;

    width:    20px;
    height:   14px;

}

.hamburger-line
{

    position:   absolute;
    top:        6px;
    width:      100%;
    height:     2px;

    display:    block;

    background: $white;

    &.post-active
    {
        &,
        &::before,
        &::after
        {
            transition: none;
        }
    }

    &,
    &::before,
    &::after
    {
        transition: all linear 0.2s;
    }

    &:before, &:after
    {
        content:    "";
        position:   absolute;
        right:      0;

        display:    block;

        height:     2px;

        background: $white;
    }

    &:before
    {
        width: 16px;
        top:   -6px;
        right: 0;
    }

    &:after
    {
        width: 13px;
        top:   6px;
        right: 0;
    }

}


