/**
 * Install dependencies
 *
 * @source npm install include-media --save
 *
 */
// Import Font
//--------------------------------------------------------------------------
// Libs
//--------------------------------------------------------------------------
@import './../../../../node_modules/include-media/dist/include-media';
//--------------------------------------------------------------------------
//--------------------------------------------------------------------------
// Fonts
//--------------------------------------------------------------------------
@import "fonts";
//--------------------------------------------------------------------------
// Variables
//--------------------------------------------------------------------------
@import 'variables/media';
@import 'variables/global';
//--------------------------------------------------------------------------
// Proto
//--------------------------------------------------------------------------
@import 'prototypes/absolute';
@import 'prototypes/eugenes_customs';
//--------------------------------------------------------------------------
// Mixin
//--------------------------------------------------------------------------
@import 'mixins/floatgrid';
@import 'mixins/inlinegrid';
@import 'mixins/grid';
@import 'mixins/media-set';
@import 'mixins/grid-container';
@import 'mixins/ivan-mixins';
//--------------------------------------------------------------------------
// Tags
//--------------------------------------------------------------------------
@import '../tags/basic';
@import '../tags/headers';
@import '../tags/html5';
@import '../tags/table';
@import '../tags/typography';
