.equipment-swiper-wrapper
{
    position: relative;
    width:    100%;

    .equipment-pagination
    {
        @include media('desctop-grid')
        {
            display: none;
        }

        &.swiper-pagination-bullets
        {
            bottom: 0;
        }

        .swiper-pagination-bullet
        {
            border: 2px solid $gray-dark;
        }

        .swiper-pagination-bullet-active
        {
            background: $gray-dark;
        }
    }
}

.equipment-swiper
{
    height:         auto;
    width:          100%;

    padding-bottom: 35px;

    @include media('m-iphone5')
    {
        padding-bottom: 40px;
    }

    @include media('desctop-grid')
    {
        padding-left:   75px;
        padding-right:  75px;
        padding-bottom: 0;

        overflow:       visible;
    }

    @include media('d-wxgap')
    {
        padding-left:  70px;
        padding-right: 70px;
    }

    .swiper-wrapper
    {
        width:  250px;
        height: auto;
        margin: 0 auto;

        @include media('desctop-grid')
        {
            width:     100%;
            display:   block;
            font-size: 0;
        }
    }

    @include media('t-ipad-max')
    {
        &.swiper-container-3d .swiper-slide
        {
            .item
            {
                border: 2px solid $gray-lightest;
            }
            transform: translate3d(0px, 10px, -14.6364px) rotateX(0deg) rotateY(0deg) !important;

            &.swiper-slide-active
            {
                transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) !important;

                .item
                {
                    border: 2px solid $orange;
                }

            }
        }
    }

    .slider_item
    {
        @extend %std-transition;

        position: relative;

        @include media('desctop-grid')
        {

            display:        none;
            vertical-align: top;
            width:          25% !important;

            padding:        0 10px;

            margin-bottom:  20px;

            @for $i from 1 through 4
            {
                &:nth-of-type(#{$i})
                {
                    display: inline-block;
                }
            }
        }

        @include media('d-wxgap')
        {
            padding: 0 15px;
        }

    }

}