//.Section_heading/
.Section_heading
{
    position: relative;
    padding-bottom: 2px;

    //.Section_heading.mod_underline
    &.mod_underline
    {

        &::after
        {
            content:          '';
            position:         absolute;
            z-index:          0;
            bottom:           -2px;
            display:          block;
            width:            16000px;
            height:           2px;
            background-color: $gray-lightest;
        }

    }

    &.mod_border-bottom
    {
        border-bottom: 2px solid $gray-lightest;
    }
}

//.Section_title-info/
.Section_title-info
{
    font-size: 16px;
    color:     $orange;

    @include media($du)
    {
        font-size: 27px;

    }
}

//.Section_title/
.Section_title
{
    color:          inherit;
    font-size:      22px;
    text-transform: uppercase;
    letter-spacing: -.004em;

    @include media($du)
    {
        font-size: 47px;

    }
}