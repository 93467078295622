.footer
{
    @extend %content;

    position:       relative;
    z-index:        1;

    padding-top:    20px;
    padding-bottom: 80px;

    width:          100%;

    margin-top:     auto;

    &.active-blur
    {
        filter:                  blur(2px);

        -webkit-backdrop-filter: blur(4px);
        backdrop-filter:         blur(4px);
    }

    @include media('t-ipad')
    {
        padding-bottom: 30px;
    }

    @include media('desctop-grid')
    {
        padding-top: 40px;
    }

    @include media('d-wxgap')
    {
        padding-bottom: 95px;
    }

    &.footer-dark-back
    {
        background: $blue;
        border-top: 2px solid #353231;
        color:      $gray-lightest;

        .footer_social-icon
        {
            fill: $white;
        }
    }

    &.footer-white-back
    {
        background: $white;
        border-top: 2px solid $gray-lightest;
        color:      $gray-dark;

        .footer_social-icon
        {
            fill: $gray-dark;
        }

    }

    &.footer-none-back
    {
        background: none;
        border:     none;

        color:      $gray-lightest;

        .footer_social-icon
        {
            fill: $white;
        }
    }

    .footer_content
    {
        display:        flex;
        align-items:    flex-start;
        flex-direction: column;

        font-size:      0;

        @include media('t-ipad')
        {
            display: block;
        }

        @include media('d-wxgap')
        {
            display:         flex;
            flex-direction:  row;
            align-items:     flex-end;

            justify-content: space-between;
        }
    }

    .footer_block
    {
        flex: 1;

        &.left
        {
            @include media('t-ipad')
            {
                display:        inline-flex;
                flex-direction: column;
                vertical-align: bottom;

                width:          50%;
            }

            @include media('d-wxgap')
            {
                display: block;
            }
        }

        &.center
        {

            margin-bottom: 30px;
            margin-top:    30px;

            @include media('t-ipad')
            {
                display: none;

                margin:  0;
            }

            @include media('d-wxgap')
            {
                display:       block;
                flex:          1 120px;
                max-width:     120px;
                min-width:     120px;

                margin-bottom: 0;
            }

        }

        &.right
        {
            display: inline-flex;

            @include media('t-ipad')
            {
                align-items:    flex-end;
                flex-direction: row;

                width:          50%;
            }
            @include media('d-wxgap')
            {
                display: block;
            }

            .footer_block-content
            {
                display:        flex;
                flex-direction: column;

                @include media('t-ipad')
                {
                    width:           100%;
                    justify-content: flex-end;
                    align-items:     flex-end;
                    flex-direction:  row;
                }

                @include media('d-wxgap')
                {
                    justify-content: flex-end;
                }
            }
        }
    }

    .footer_logo
    {
        width:         25px;
        height:        25px;

        fill:          $orange;

        margin-bottom: 6px;

        @include media('desctop-grid')
        {
            width:  35px;
            height: 35px;
        }
    }

    .footer_marker
    {
        display:       block;
        margin-bottom: 2px;
        margin-right:  5px;

        width:         19px;
        height:        23px;

        fill:          $orange;

        @include media('d-wxgap')
        {
            margin-bottom: 5px;
            margin-right:  0;
        }
    }

    .footer_social-icon
    {
        @extend %std-transition;

        display:      block;

        width:        19px;
        height:       22px;

        margin-right: 2px;
    }

    .footer_on-map
    {
        @extend %std-transition;

        display:     flex;

        align-items: flex-end;

        font-size:   16px;

        @include media('desctop-grid')
        {

            &:hover
            {
                color: $orange;
            }
        }

        @include media('d-wxgap')
        {
            align-items:    center;
            flex-direction: column;
        }

    }

    .md_footer_on-map
    {
        display:    none;

        margin-top: 25px;

        .footer_on-map
        {
            display: inline-flex;
            width:   33.33%;
        }

        @include media('t-ipad')
        {
            display: flex;
        }

        @include media('d-wxgap')
        {
            display: none;
        }
    }

    .footer_address
    {
        @extend %other-font;

        font-size:   10px;
        line-height: 16px;

        @include media('desctop-grid')
        {
            font-size:   14px;
            line-height: 23px;
        }

    }

    .footer_question
    {
        font-size:     24px;
        margin-bottom: 4px;

        @include media('t-ipad')
        {
            flex:          1;

            margin-bottom: 0;
            line-height:   18px;

            text-align:    right;

            margin-right:  25px;
        }

        @include media('desctop-grid')
        {
            margin-bottom: 0;
            font-size:     36px;

            line-height:   28px;

        }

        @include media('>=1280px')
        {
            font-size:   48px;

            line-height: 29px;
        }
    }

    .footer_social
    {
        @extend %std-transition;

        display:     inline-flex;
        align-items: center;

        font-size:   14px;
        padding:     4px 12px 4px 7px;

        border:      2px solid $orange;

        span
        {
            @extend %std-transition;
        }

        &:hover
        {
            background: $orange;

            & .footer_social-icon
            {
                fill: $white;
            }

            & span
            {
                color: $white;
            }
        }

    }

    .footer_contacts-link
    {
        font-size:      16px;

        padding-bottom: 6px;
    }

    .arrow
    {
        @extend %arrow-top;

        bottom: 20px;

        svg
        {
            fill: $orange;
        }

        @include media('t-ipad')
        {
            bottom: 24px;
        }

        @include media('d-wxgap')
        {
            bottom: 25px;
        }
    }
}

