//.projects_heading/
.projects_heading
{
    color:         $gray-dark;

    padding-top:   $gutter-width * 1.5;
    padding-left:  $gutter-width;
    padding-right: $gutter-width;

    @include media($du)
    {
        padding-top:   $gutter-width * 2;
        padding-left:  $gutter-width * 2;
        padding-right: $gutter-width * 2;
    }

    @at-root .projects.mod_latest .projects_heading
    {
        @include media($tu)
        {
            padding-top: 31px;
        }
    }
    @at-root .projects.mod_completed .projects_heading
    {
        @include media($tu)
        {
            margin-bottom: 4px;
        }
    }

}

//.projects_title/
.projects_title
{

}