
.page_support
{
    @include media('t-ipad')
    {
        height: 100%;
    }

    .header
    {
        background: rgba(0, 0, 0, 0.7);
    }
}

.support-screen-1
{
    position: relative;

    height:   450px;

    @include media('t-ipad')
    {
        height: 100%;
    }

    .support_content
    {
        position:            relative;
        height:              100%;

        display:             flex;
        flex-direction:      column;
        justify-content:     flex-end;

        background-size:     cover;
        background-position: center;

        padding:             20px 20px 60px 20px;

        @include media('desctop-grid')
        {
            padding: 40px 40px 8vh 40px;
        }

        .title
        {
            @extend %section_title;

            margin-bottom: 2px;

            @extend %titles-shadow;
        }

        .subtitle
        {
            @extend %section_subtitle;

            @extend %titles-shadow;
        }

        .description
        {
            @extend %section_description;

            margin: 10px 0 15px 0;

            @include media('desctop-grid')
            {
                margin: 8px 0 20px 0;
            }

            @include media('d-wxgap')
            {
                margin: 0 0 20px 0;
            }
        }
    }

    .arrow
    {
        @extend %arrow-down;

        display: none;

        @include media('t-ipad')
        {
            display: block;
        }
    }
}