.page_error
{
    height: 100%;

    .container
    {
        display:        flex;
        flex-direction: column;

        .footer
        {
            border-top: none;
        }

        .header
        {
            position:   relative;
            background: $blue;
        }
    }

    main
    {
        display:     flex;
        align-items: stretch;
        background:  $blue;
        height:      auto;
        flex:        1;
    }

    .error-content
    {
        display:         flex;
        flex-direction:  column;
        align-items:     center;
        justify-content: center;

        width:           100%;

        color:           $white;
        text-align:      center;

        height:          300px;

        padding-left:    20px;
        padding-right:   20px;

        @include media('t-ipad')
        {
            height: 500px;
        }

        @include media('desctop-grid')
        {
            height: 100%;
        }

        .error_title
        {
            font-size: 100px;
            color:     $orange;

            @include media('desctop-grid')
            {
                font-size:   300px;
                line-height: 250px;
            }

            @include media('desctop-max-height')
            {
                font-size:   80px;
                line-height: normal;
            }
        }

        .error_button
        {
            width:      225px;
            margin-top: 30px;
        }

        .error_message
        {
            @extend %other-font;

            font-size: 20px;
        }
    }
}