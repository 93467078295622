//.interactive/
.interactive
{
  max-width: 320px;
  
  //.interactive_pre-title
  &_pre-title
  {
    font-size: 20px;
    color: $orange;
    
    @include media($du)
    {
      
    }
  }
  //.interactive_title
  &_title
  {
    font-size: 24px;
    
    @include media($du)
    {
      font-size: 29px;
      line-height: 1.1;
    }
  }
  //.interactive_button/
  &_button
  {
    margin-top: $gutter-width;
    
    @include media($du)
    {
      margin-top: 8px;
    }
  }
}