.equipment_list
{

    @extend %content;

    padding-top: 30px;

    @include media('desctop-grid')
    {
        display:     flex;
        align-items: flex-start;
    }

    .section-left
    {

        margin-bottom: 40px;

        @include media('desctop-grid')
        {
            position:      fixed;
            z-index:       2;
            left:          40px;
            top:           205px;

            width:         375px;
            max-width:     375px;
            min-width:     375px;

            padding-right: 40px;

            margin-bottom: 0;
        }
    }

    .equipment_list-content
    {
        font-size:    0;

        margin-left:  -10px;
        margin-right: -10px;
        margin-top:   25px;

        @include media('d-wxgap')
        {
            margin-right: -15px;
            margin-left:  -15px;
        }
    }

    .equipment_item
    {
        display:        inline-block;
        vertical-align: top;

        width:          50%;

        padding:        0 10px;

        margin-bottom:  25px;

        @include media('d-only')
        {
            width: 33.33%;
        }

        @include media('d-wxgap')
        {
            padding: 0 15px;
        }

        .equipment-preview
        {
            height: 200px;

            @include media('t-ipad')
            {
                height: 275px;
            }

            @include media('desctop-grid')
            {
                height: 265px;
            }

            @include media('d-wxgap')
            {
                height: 315px;
            }

            .item_title
            {
                font-size: 15px;

                @include media('t-ipad')
                {
                    font-size: 20px;
                }
            }

            .item_image-container
            {

                top:             0;

                @include media('t-ipad')
                {
                    top:             28px;
                    background-size: contain;
                }
            }
        }
    }

    .section-right
    {
        position: relative;

        @include media('desctop-grid')
        {
            padding-left: 375px;
            padding-top:  35px;

            flex:         1 100%;
        }

    }

    .equipment_list-block
    {
        margin-bottom: 25px;
    }

    .left_menu
    {
        padding-top: 20px;
    }
}

.page_equipment
{
    .Breadcrumbs
    {
        @include media('desctop-grid')
        {
            position: fixed;
            z-index:  3;
            left:     0;
            top:      140px;
        }

    }
}

.equipment_all
{
    width:  225px;
    margin: 40px auto 0;
}