html
{
    @include media('t-ipad')
    {
        height: 100%;
    }

    @include media(">=2500px")
    {
        zoom: 1.5;
    }

    @include media(">=3200px")
    {
        zoom: 2;
    }
}

body
{
    @extend %main-font;

    position:                 relative;

    min-width:                320px;
    background:               $white;

    -webkit-text-size-adjust: none;

}

main
{
    @extend %std-transition;

    @include media('t-ipad')
    {
        height: 100%;
    }

    &.active-blur
    {
        filter: blur(2px);

        -webkit-backdrop-filter: blur(4px);
        backdrop-filter:         blur(4px);
    }
}

img
{
    display:   block;
    max-width: 100%;
}

svg
{
    display: block;
}

use
{
    display: block;
}

iframe
{

}

video
{

}

a
{
    display:         block;
    text-decoration: none;
    color:           inherit;
}

button, input
{
    &:focus
    {
        outline: none;
    }
}