.home-screen-1
{
    position:   relative;
    background: $black;

    height:     600px;

    @include media('t-ipad')
    {
        height: 100%;
    }

    &.md_color-light
    {
        color: $gray-lightest;
    }

    .section_title
    {
        position:       absolute;
        left:           0;
        right:          0;
        top:            80px;
        z-index:        9;

        text-transform: uppercase;
        padding:        0 20px;

        color:          $gray-dark;

        @include media('desctop-grid')
        {
            top:     100px;
            padding: 0 40px;
        }

        & > span
        {
            display:       block;

            font-size:     0;

            margin-bottom: 2px;

            & > span
            {
                display:     inline-block;

                font-size:   16px;
                line-height: 12px;

                background:  $gray-lightest;

                padding:     5px 4px 2px 4px;
            }
        }

    }

    .top-gradient
    {
        position:   absolute;

        left:       0;
        top:        0;
        right:      0;
        z-index:    1;

        height:     28%;

        background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 62%, rgba(0, 0, 0, 0.73) 78%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 62%, rgba(0, 0, 0, 0.73) 78%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 62%, rgba(0, 0, 0, 0.73) 78%, rgba(0, 0, 0, 0) 100%);
        filter:     progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);

        @include media('desctop-grid')
        {
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7) 26%, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0.5) 66%, rgba(0, 0, 0, 0.01) 99%, rgba(0, 0, 0, 0) 100%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7) 26%, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0.5) 66%, rgba(0, 0, 0, 0.01) 99%, rgba(0, 0, 0, 0) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7) 26%, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0.5) 66%, rgba(0, 0, 0, 0.01) 99%, rgba(0, 0, 0, 0) 100%);
            filter:     progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
        }

    }

    .bottom-gradient
    {
        position:   absolute;

        left:       0;
        bottom:     0;
        right:      0;
        z-index:    1;

        height:     59%;

        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.73) 13%, rgba(0, 0, 0, 1) 24%, rgba(0, 0, 0, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.73) 13%, rgba(0, 0, 0, 1) 24%, rgba(0, 0, 0, 1) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.73) 13%, rgba(0, 0, 0, 1) 24%, rgba(0, 0, 0, 1) 100%);
        filter:     progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);

        @include media('desctop-grid')
        {
            height: 40%;
        }

    }

    .arrow
    {

        @extend %arrow-down;

        display: none;

        @include media('t-ipad')
        {
            display: block;
        }
    }

    .item_button
    {
        position:   absolute;
        left:       0;
        right:      0;
        bottom:     92px;

        text-align: center;

        @include media('t-ipad')
        {
            text-align: left;

            bottom:     12vh;
        }
    }

    .item_link
    {
        @include go-button(-15px, -35px);
        z-index: 9;

        @include media('t-ipad')
        {
            margin-left: 50px;
        }

        @include media('desctop-grid')
        {
            margin-left: 85px;
        }
    }

}

.main-swiper
{
    height: 100%;

    .slider_item.item
    {
        background-size:     cover;
        background-position: center;
        background-repeat:   no-repeat;

        height:              100%;

        .item_content
        {
            position:        relative;
            z-index:         2;

            display:         flex;
            flex-direction:  column;

            justify-content: flex-end;

            height:          100%;

            padding:         20px 20px 140px 20px;

            @include media('t-ipad')
            {
                padding: 20px 20px 17vh 20px;
            }

            @include media('desctop-grid')
            {
                padding: 40px 40px 17vh 40px;
            }
        }

        .item_title
        {
            @extend %section_title;

            margin-bottom: 2px;

            @extend %titles-shadow;
        }

        .item_subtitle
        {
            @extend %section_subtitle;

            @extend %titles-shadow;
        }

        .item_description
        {
            @extend %section_description;

            margin: 10px 0 15px 0;

            @include media('desctop-grid')
            {
                margin: 8px 0 20px 0;
                height: auto;
            }

            @include media('d-wxgap')
            {
                margin: 0 0 20px 0;
            }

        }
    }

}

.swiper-container-horizontal > .swiper-pagination-bullets
{

    bottom: 57px;

    @include media('t-ipad')
    {
        bottom: 9%;
    }

    @include media('desctop-grid')
    {
        bottom: 8%;
    }
}


