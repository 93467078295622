@mixin grid-padding
{
    padding-left: $gutter-width;
    padding-right: $gutter-width;

}

@mixin grid-container-variable($width : $grid-width) {
    max-width: $width;
    margin-left: auto;
    margin-right: auto;

}
