.page_motion-design
{
    height: 100%;

    .header
    {
        background: none;
    }

}

.motion-design
{
    position:   relative;

    background: $blue;

    @include media('t-ipad')
    {
        height: 100%;

    }

    &.md_color-light
    {
        color: $gray-lightest;
    }

    .section_back
    {

    }

    .section_content
    {
        position:            relative;
        z-index:             1;

        display:             flex;
        align-items:         flex-start;
        justify-content:     center;
        flex-direction:      column;

        min-height:          100%;

        background-image:    url("/img/motion-design-back.jpg");
        background-size:     cover;
        background-position: center top;
    }

    .section_titles
    {
        display:         flex;
        flex:            1;
        flex-direction:  column;
        justify-content: flex-end;

        padding:         80px 20px 40px 20px;

        @include media('t-ipad')
        {
            height:          auto;
            justify-content: flex-end;
            padding:         80px 20px 0 20px;
        }

        @include media('desctop-grid')
        {

            padding: 140px 80px 0 40px;
        }
    }

    .section_logo
    {
        display:       block;
        max-width:     170px;
        width:         100%;
        height:        auto;

        margin-bottom: 14px;

        @include media('t-ipad')
        {
            max-width: 200px;
        }

        @include media('desctop-grid')
        {
            max-width: 310px;
        }

    }

    .section_title
    {
        @extend %section_subtitle;

        @extend %titles-shadow;
    }

    .section_description
    {
        @extend %section_description;

        margin: 5px 0 20px 0;

        &:before
        {
            background: $gray-lightest;
        }
    }

    .section_button
    {
        font-size: 0;
    }

    .section_link
    {
        color:         $gray-lightest;

        @include go-button(-20px, -35px);

        margin-left:   30px;

        margin-bottom: 25px;

        @include media('t-ipad')
        {
            margin-bottom: 0;
        }

        @include media('desctop-grid')
        {
            margin-left: 45px;
        }

        &.md_second
        {
            @include go-button(-20px, -35px, 12px 44px);

            @include media('t-ipad')
            {
                margin-left: 26px;
            }

        }
    }
}

