
.Go-to_svg
{
    display:        inline-block;
    vertical-align: middle;
    width:          28px;
    height:         14px;
    margin-left:    5px;
    fill:           $orange;

    &.mod_left
    {

    }

    &.mod_right
    {

    }
}

.Button-std
{
    @extend %btn-reset-default;
    @extend %main-font;
    @extend %std-transition;

    min-height:   45px;

    min-width:    225px;

    padding:      12px 11px 10px 11px;

    font-size:    16px;

    border:       2px solid;

    text-align:   center;

    color:        inherit;

    border-color: $orange;

    transition:   background $transition-std;

    cursor:       pointer;

    &:hover
    {
        background-color: $orange;

        color:            $white;
    }

    &.disabled
    {
        cursor: default;
    }

    &.mod_dark
    {

    }
    &.mod_light
    {

    }

}

@at-root .Mod_dark
{
    .Go-to_svg
    {
        fill:       #ffffff;
        transition: fill $transition-std;

    }

    .Arrow-pagination_link
    {
        color:      #ffffff;
        transition: color $transition-std;

        &:not(.disabled):hover
        {
            color:  $orange;
            cursor: pointer;

            .Go-to_svg
            {
                fill: $orange;
            }
        }
        &.disabled
        {
            color:  $gray-light;
            cursor: default;
            .Go-to_svg
            {
                fill: $gray-light
            }
        }

    }
}

.Arrow-pagination
{
    display:         flex;
    justify-content: space-between;

    //.Arrow-pagination .Go-to_svg
    .Go-to_svg
    {
        margin-top: 1px;

    }
    //.Arrow-pagination_link/
    &_link
    {
        display:     flex;
        font-size:   18px;
        align-items: baseline;

        &.mod_prev
        {
            text-align: left;
            .Go-to_svg
            {
                transform:        rotateZ(180deg);
                -moz-transform:   rotateZ(180deg);
                transform-origin: center center;
                margin-left:      0;
                margin-right:     8px;

            }
        }

        &.mod_next
        {
            text-align: right;
            .Go-to_svg
            {
                margin-left: 8px;

            }

        }
        &.mod_disabled
        {
            .Go-to_svg
            {
                display: none;
                @at-root .mod_dark &
                {
                    fill: $gray-light;
                }
            }
        }

    }
}


