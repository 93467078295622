p
{
    word-wrap: break-word;

    a
    {
        display:   inline;

    }
}

ul, ol
{

}

ul
{

}

ol
{

}

li
{
    list-style-type: none;
    word-wrap:       break-word;
}

b
{

}

strong
{
    font-weight: 700;
}

i
{

}

em
{

}

mark
{

}

time
{

}

small
{

}

span
{
    display:   inline-block;
    word-wrap: break-word;
}