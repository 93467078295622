.support-screen-2
{
    .support_content
    {
        @extend %content;

        padding-top:    40px;
        padding-bottom: 40px;

        width:          100%;

        @include media('t-ipad')
        {
            display:         flex;
            justify-content: center;
            align-items:     flex-start;

            padding-top:     80px;
            padding-bottom:  60px;
        }
    }

    .left-content
    {

        margin-bottom: 40px;

        @include media('t-ipad')
        {
            flex:          1 50%;
            width:         50%;
            min-width:     50%;
            max-width:     50%;

            padding-right: 20px;
            margin-bottom: 0;
        }

        @include media('desctop-grid')
        {
            padding-right: 40px;
        }
    }

    .right-content
    {

        @include media('t-ipad')
        {

            flex:         1 50%;
            width:        50%;
            padding-left: 20px;
        }

        @include media('desctop-grid')
        {
            padding-left: 40px;
        }
    }

    @include media('desctop-grid')
    {

    }

    .support_title
    {
        @extend %section_title;

        font-size:     24px;
        color:         $orange;

        margin-bottom: 15px;

        @include media('desctop-grid')
        {
            font-size: 44px;
        }

        @include media('d-wxgap')
        {
            font-size: 48px;
        }

        &.mod_sup_color
        {
            color: $gray-dark;
        }
    }

    .support_form-content
    {
        position:  relative;

        width:     100%;
        max-width: 575px;
    }

    .support_form
    {
        .order_form
        {
            max-width: 100%;
            padding:   0;

        }

        .order_field
        {

            border-bottom: 2px solid #d4cfcd;

            &.active
            {
                border-bottom: 2px solid $gray-dark;
            }
        }

        .order_input
        {
            color: $gray-dark;
        }

        .order_label
        {
            color: #464646;
        }

        .order_form-button
        {
            width:      100%;
            margin-top: 15px;
        }
    }

    .support_accordion-content
    {
        @extend %main-second-font;
    }

    .support_accordion-item.item
    {
        margin-bottom: 20px;

        .item_title
        {
            position:      relative;
            display:       inline-block;

            padding-right: 15px;

            font-size:     20px;
            color:         #232323;

            .icon
            {
                @extend %std-transition;

                position:   relative;
                top:        -3px;
                right:      -6px;

                width:      8px;
                height:     8px;
                background: url("/img/plus.svg");
            }

            &.active
            {
                .icon
                {
                    transform: rotateZ(45deg);
                }
            }
        }

        .item_description
        {
            @extend %other-font;

            font-size:    16px;
            color:        #434343;

            padding-top:  10px;
            padding-left: 10px;

            @include media('desctop-grid')
            {
                padding-top:  20px;
                padding-left: 20px;
            }
        }
    }

}