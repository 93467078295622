@import "equipment/preview";
@import "equipment/list";
@import "equipment/detail";

.page_equipment-detail main
{
    margin-top:    80px;
    margin-bottom: 50px;
    overflow:      hidden;
    height:        auto;

    @include media($du)
    {
        margin-top:    140px;
        margin-bottom: 65px;
    }
}

.page_equipment main
{
    padding-top:    80px;
    padding-bottom: 25px;
    overflow:       hidden;
    height:         auto;

    @include media($du)
    {
        padding-top:    140px;
        padding-bottom: 40px;
    }
}