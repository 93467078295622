
.Breadcrumbs
{

    font-size: 0;
    @include media($du)
    {
        margin-top: 15px;
    }

    .Breadcrumb-arrow
    {
        display: none;

        @include media('t-ipad')
        {
            display: block;
        }
    }

}

.Breadcrumbs_list
{
    font-size: 0;
    @include media($du)
    {
        padding-left:  $gutter-width;
        padding-right: $gutter-width;
    }
}

.Breadcrumbs_li
{
    @include grid-padding();
    position:       relative;
    vertical-align: top;
    color:          #959595;

    @include media($tu)
    {
        display: inline-block;
    }
    &.mod_mob
    {

        @include media($td)
        {
            display: inline-block;
        }
        @include media($tu)
        {
            display: none;
        }

    }

}

.Breadcrumbs_link
{
    color:       inherit;
    font-size:   16px;
    transition:  color $transition-std;

    line-height: 20px;

    &.active
    {
        color:  $gray-dark;
        cursor: default;

    }
    &:not(.active):hover
    {
        color: $orange;

    }

}

.Breadcrumb-arrow
{
    position:         absolute;
    right:            -3px;
    top:              5px;
    height:           7px;
    width:            7px;
    fill:             #959595;
    transform:        rotateZ(180deg);
    -moz-transform:   rotateZ(180deg);
    transform-origin: center center;

    transition:       fill $transition-std;

    @at-root .Breadcrumbs_link:not(.active):hover .Breadcrumb-arrow
    {
        fill: $orange;
    }
    @at-root .Breadcrumbs_li.mod_mob .Breadcrumb-arrow
    {
        left:           9px;
        right:          auto;
        transform:      rotateZ(0deg);
        -moz-transform: rotateZ(0deg);

    }
    @at-root .Breadcrumbs_li:last-of-type .Breadcrumb-arrow
    {
        display: none;
    }
}

// breadcrumbs secondary /////////////////////////////////////////////////////////////////////////

.breadcrumbs-secondary_wrapper
{
    position:      relative;
    margin-bottom: 25px;

    &.mod_underline
    {

        &::after
        {
            content:          '';
            position:         absolute;
            z-index:          0;
            bottom:           -7px;
            display:          block;
            width:            16000px;
            height:           2px;
            background-color: $gray-lightest;
        }

    }
}

.breadcrumbs-secondary_list
{

    @include media($td)
    {
        white-space: nowrap;
        overflow:    auto;
    }

}

//.breadcrumbs-secondary_li/
.breadcrumbs-secondary_li
{
    display:        inline-block;
    vertical-align: top;
    font-size:      16px;
    color:          #959595;
    padding-right:  12px;

    &.Active
    {
        color: $gray-dark;

        .breadcrumbs-secondary_link
        {
            color:  inherit;
            cursor: default;
        }
    }

}

//.breadcrumbs-secondary_link/
.breadcrumbs-secondary_link
{
    color:      inherit;
    transition: color $transition-std;

    cursor:     pointer;

    &:hover
    {
        color: $orange;

    }
}