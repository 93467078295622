@charset "UTF-8";
/**
 * Install dependencies
 *
 * @source npm install include-media --save
 *
 */
.Slider-arrow, .Button-std, .projects-previews_show-more {
  background-color: transparent;
  -webkit-appearance: none;
  border: none;
  outline: none;
}

.project-slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/**
* Миксин формирует колоночную сетку с помощью float
*
* @var string $column Колличество колонок
* @var string $indent Отступ между колонками
*
* @example "@include floatgrid(2, 20px)"
* @example "@include floatgrid(6, 10px)"
* @example "@include floatgrid(8)"
*/
.project_top::before, .project_top::after {
  display: table;
  content: ' ';
}

.project_top::after {
  clear: both;
}

/**
* Миксин формирует колоночную сетку с помощью inline-block
*
* @var string $column Колличество колонок
* @var string $indent Отступ между колонками
*
* @example "@include inlinegrid(2, 20px)"
* @example "@include inlinegrid(6, 10px)"
* @example "@include inlinegrid(8)"
*/
/**
* Миксин формирует колоночную сетку с помощью float
*
* @var string $column Колличество колонок
* @var string $indent Отступ между колонками
*
* @example "@include grid(3, 20px)"
* @example "@include grid(6, 10px)"
* @example "@include grid(8)"
*/
/**
* Миксин группирует свойства по медиа запросам
*
* @var string $property Изменяемое свойство
* @var string $values Массив со значениями для разрешения
*
* @example "@include media-set(font-size, (m: 12px, t: 14px, d: 16px))"
* @example "@include media-set(margin-bottom, (m: 10px, d: 30px, d-3k: 50px))"
*/
@media (min-width: 768px) {
  html {
    height: 100%;
  }
}

@media (min-width: 2500px) {
  html {
    zoom: 1.5;
  }
}

@media (min-width: 3200px) {
  html {
    zoom: 2;
  }
}

body {
  position: relative;
  min-width: 320px;
  background: #fff;
  -webkit-text-size-adjust: none;
}

@media (min-width: 768px) {
  main {
    height: 100%;
  }
}

main.active-blur {
  filter: blur(2px);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

img {
  display: block;
  max-width: 100%;
}

svg {
  display: block;
}

use {
  display: block;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
}

button:focus, input:focus {
  outline: none;
}

table {
  background: none !important;
}

table p {
  padding-bottom: 0 !important;
  line-height: normal !important;
}

td {
  border: 1px solid #000 !important;
  background: none !important;
  padding-left: 5px;
}

p {
  word-wrap: break-word;
}

p a {
  display: inline;
}

li {
  list-style-type: none;
  word-wrap: break-word;
}

strong {
  font-weight: 700;
}

span {
  display: inline-block;
  word-wrap: break-word;
}

.svg-container {
  display: none;
}

.container {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.md_fill-light {
  fill: #f8f3f1;
}

.Slider-arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%) scale(1);
  display: none;
  cursor: pointer;
}

@media (min-width: 1001px) {
  .Slider-arrow {
    display: block;
  }
}

.Slider-arrow.swiper-arrow-disabled {
  cursor: default;
}

@media (min-width: 1001px) {
  .Slider-arrow.mod_prev {
    left: -10px;
  }
}

@media (min-width: 1001px) {
  .Slider-arrow.mod_next {
    right: -10px;
  }
}

.Slider-arrow.mod_boxed {
  background-color: rgba(13, 13, 39, 0.4);
  padding: 12px 17px;
}

.Slider-arrow.mod_boxed.mod_prev {
  left: 40px;
}

.Slider-arrow.mod_boxed.mod_next {
  right: 40px;
}

.Slider-arrow_svg {
  width: 9px;
  height: 19px;
  transition: fill ease-out 0.4s;
}

@media (min-width: 1001px) {
  .Slider-arrow:not(.swiper-arrow-disabled):hover .Slider-arrow_svg {
    fill: #f37036;
  }
}

@media (max-width: 1001px) {
  .Slider-arrow:not(.swiper-arrow-disabled):active .Slider-arrow_svg {
    fill: #f37036;
  }
}

.Slider_pagination {
  left: 0;
  right: 0;
  transform: translateX(-50%);
  text-align: center;
}

.Slider_pagination .swiper-pagination-bullet {
  position: relative;
  width: 9px;
  height: 9px;
  background-color: transparent;
  margin: 0 6px;
  opacity: 1;
  transition: background ease-out 0.4s, border ease-out 0.4s, transform ease-out 0.4s;
}

.Slider_pagination .swiper-pagination-bullet::before {
  content: '';
  position: absolute;
  top: -9px;
  left: -9px;
  width: 22px;
  height: 22px;
  display: block;
  cursor: pointer;
}

.Slider_pagination .swiper-pagination-bullet-active {
  transform: scale(1.3);
}

.Slider_pagination.mod_boxed {
  bottom: 0 !important;
  padding: 13px 0;
  background-color: rgba(13, 13, 39, 0.4);
}

@media (min-width: 768px) {
  .Slider_pagination.mod_boxed {
    padding: 30px 0;
  }
}

.Mod_light .swiper-pagination-bullet-active {
  background-color: #000012;
}

.Mod_light .Slider-arrow_svg {
  fill: #000000;
}

.Mod_light .swiper-pagination-bullet {
  border: 2px solid #000012;
}

.Mod_dark .swiper-pagination-bullet-active {
  background-color: #fff;
}

.Mod_dark .swiper-pagination-bullet {
  border: 2px solid #fff;
}

.Mod_dark .Slider-arrow_svg {
  fill: #fff;
}

.Go-to_svg {
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 14px;
  margin-left: 5px;
  fill: #f37036;
}

.Button-std {
  min-height: 45px;
  min-width: 225px;
  padding: 12px 11px 10px 11px;
  font-size: 16px;
  border: 2px solid;
  text-align: center;
  color: inherit;
  border-color: #f37036;
  transition: background ease-out 0.4s;
  cursor: pointer;
}

.Button-std:hover {
  background-color: #f37036;
  color: #fff;
}

.Button-std.disabled {
  cursor: default;
}

.Mod_dark .Go-to_svg {
  fill: #ffffff;
  transition: fill ease-out 0.4s;
}

.Mod_dark .Arrow-pagination_link {
  color: #ffffff;
  transition: color ease-out 0.4s;
}

.Mod_dark .Arrow-pagination_link:not(.disabled):hover {
  color: #f37036;
  cursor: pointer;
}

.Mod_dark .Arrow-pagination_link:not(.disabled):hover .Go-to_svg {
  fill: #f37036;
}

.Mod_dark .Arrow-pagination_link.disabled {
  color: #959595;
  cursor: default;
}

.Mod_dark .Arrow-pagination_link.disabled .Go-to_svg {
  fill: #959595;
}

.Arrow-pagination {
  display: flex;
  justify-content: space-between;
}

.Arrow-pagination .Go-to_svg {
  margin-top: 1px;
}

.Arrow-pagination_link {
  display: flex;
  font-size: 18px;
  align-items: baseline;
}

.Arrow-pagination_link.mod_prev {
  text-align: left;
}

.Arrow-pagination_link.mod_prev .Go-to_svg {
  transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  transform-origin: center center;
  margin-left: 0;
  margin-right: 8px;
}

.Arrow-pagination_link.mod_next {
  text-align: right;
}

.Arrow-pagination_link.mod_next .Go-to_svg {
  margin-left: 8px;
}

.Arrow-pagination_link.mod_disabled .Go-to_svg {
  display: none;
}

.mod_dark .Arrow-pagination_link.mod_disabled .Go-to_svg {
  fill: #959595;
}

.footer, .home-screen-2 .section_titles, .support-screen-2 .support_content, .equipment_list, .equipment_detail {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1001px) {
  .footer, .home-screen-2 .section_titles, .support-screen-2 .support_content, .equipment_list, .equipment_detail {
    padding-left: 40px;
    padding-right: 40px;
  }
}

main, .Button-std, .equipment-preview .item_go, .equipment-preview .item_go span, .equipment-preview .item_go svg, .home-screen-1 .arrow svg, .home-screen-2 .arrow svg, .support-screen-1 .arrow svg, .footer .arrow svg, .footer .footer_social-icon, .footer .footer_on-map, .footer .footer_social, .footer .footer_social span, .header, .header .logo:after, .header .logo .logo_icon-text, .header .header_menu, .header .menu .menu_link, .left_menu.menu .menu_item-title, .left_menu.menu .menu_sublink, .left_menu.menu .menu_sublink:before, .equipment-swiper .slider_item, .order, .order .order_close svg, .order_form .order_label, .order_response, .big-vertical-swiper .swiper-pagination.vertical-pagination .swiper-pagination-bullet:before, .projects-slide .projects-preview_link, .projects-slide.swiper-slide-active .projects-preview_link, .support-screen-2 .support_accordion-item.item .item_title .icon, .equipment-preview.item, .equipment-preview .item_title, .equipment_detail .detail_navigation li {
  transition: all linear 0.2s;
}

.big-vertical-swiper .swiper-pagination.vertical-pagination .swiper-pagination-bullet {
  transition: all linear 0.1s;
}

.home-screen-1 .item_link, .home-screen-1 .item_link span, .home-screen-1 .item_link svg, .home-screen-3 .section_link, .home-screen-3 .section_link span, .home-screen-3 .section_link svg, .motion-design .section_link, .motion-design .section_link span, .motion-design .section_link svg, .motion-design .section_link.md_second, .motion-design .section_link.md_second span, .motion-design .section_link.md_second svg, .invitation .section_link, .invitation .section_link span, .invitation .section_link svg, .invitation .section_link.md_second, .invitation .section_link.md_second span, .invitation .section_link.md_second svg {
  transition: all ease-out 0.4s;
}

.equipment-preview .item_go {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  font-size: 16px;
}

.equipment-preview .item_go span {
  position: relative;
  left: 0;
  line-height: 15px;
  padding-top: 2px;
  color: #f37036;
}

.equipment-preview .item_go svg {
  position: relative;
  width: 25px;
  height: 15px;
  fill: #f37036;
  margin-left: 5px;
}

.main-swiper .slider_item.item .item_title, .home-screen-2 .section_title, .home-screen-3 .section_title, .support-screen-1 .support_content .title, .support-screen-2 .support_title {
  font-size: 24px;
  color: #f37036;
}

@media (min-width: 1001px) {
  .main-swiper .slider_item.item .item_title, .home-screen-2 .section_title, .home-screen-3 .section_title, .support-screen-1 .support_content .title, .support-screen-2 .support_title {
    font-size: 44px;
  }
}

@media (min-width: 1440px) {
  .main-swiper .slider_item.item .item_title, .home-screen-2 .section_title, .home-screen-3 .section_title, .support-screen-1 .support_content .title, .support-screen-2 .support_title {
    font-size: 54px;
  }
}

.main-swiper .slider_item.item .item_subtitle, .home-screen-3 .section_subtitle, .support-screen-1 .support_content .subtitle, .motion-design .section_title, .invitation .section_title {
  font-size: 34px;
  line-height: 32px;
  color: #f8f3f1;
}

@media (min-width: 1001px) {
  .main-swiper .slider_item.item .item_subtitle, .home-screen-3 .section_subtitle, .support-screen-1 .support_content .subtitle, .motion-design .section_title, .invitation .section_title {
    font-size: 54px;
    line-height: normal;
  }
}

@media (min-width: 1440px) {
  .main-swiper .slider_item.item .item_subtitle, .home-screen-3 .section_subtitle, .support-screen-1 .support_content .subtitle, .motion-design .section_title, .invitation .section_title {
    font-size: 72px;
  }
}

.main-swiper .slider_item.item .item_description, .home-screen-2 .section_description, .home-screen-3 .section_description, .support-screen-1 .support_content .description, .motion-design .section_description, .invitation .section_description {
  padding-left: 30px;
}

@media (min-width: 1001px) {
  .main-swiper .slider_item.item .item_description, .home-screen-2 .section_description, .home-screen-3 .section_description, .support-screen-1 .support_content .description, .motion-design .section_description, .invitation .section_description {
    padding-left: 45px;
  }
}

.main-swiper .slider_item.item .item_description, .home-screen-2 .section_description, .home-screen-3 .section_description, .support-screen-1 .support_content .description, .motion-design .section_description, .invitation .section_description {
  position: relative;
  font-size: 12px;
  line-height: 15px;
  max-width: 1010px;
  width: 100%;
  color: #d4cfcd;
}

.main-swiper .slider_item.item .item_description:before, .home-screen-2 .section_description:before, .home-screen-3 .section_description:before, .support-screen-1 .support_content .description:before, .motion-design .section_description:before, .invitation .section_description:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 20px;
  height: 2px;
  background: #d4cfcd;
}

@media (min-width: 1001px) {
  .main-swiper .slider_item.item .item_description, .home-screen-2 .section_description, .home-screen-3 .section_description, .support-screen-1 .support_content .description, .motion-design .section_description, .invitation .section_description {
    font-size: 16px;
    line-height: 22px;
  }
  .main-swiper .slider_item.item .item_description:before, .home-screen-2 .section_description:before, .home-screen-3 .section_description:before, .support-screen-1 .support_content .description:before, .motion-design .section_description:before, .invitation .section_description:before {
    width: 30px;
    top: 9px;
  }
}

@media (min-width: 1440px) {
  .main-swiper .slider_item.item .item_description, .home-screen-2 .section_description, .home-screen-3 .section_description, .support-screen-1 .support_content .description, .motion-design .section_description, .invitation .section_description {
    line-height: 25px;
  }
  .main-swiper .slider_item.item .item_description:before, .home-screen-2 .section_description:before, .home-screen-3 .section_description:before, .support-screen-1 .support_content .description:before, .motion-design .section_description:before, .invitation .section_description:before {
    top: 10px;
  }
}

.home-screen-1 .arrow, .home-screen-2 .arrow, .support-screen-1 .arrow {
  position: absolute;
  bottom: 10px;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  cursor: pointer;
}

@media (min-width: 1001px) {
  .home-screen-1 .arrow, .home-screen-2 .arrow, .support-screen-1 .arrow {
    bottom: 25px;
  }
  .home-screen-1 .arrow:hover svg, .home-screen-2 .arrow:hover svg, .support-screen-1 .arrow:hover svg {
    fill: #f37036;
  }
}

.home-screen-1 .arrow svg, .home-screen-2 .arrow svg, .support-screen-1 .arrow svg {
  width: 20px;
  height: 10px;
}

.footer .arrow {
  position: absolute;
  bottom: 10px;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  cursor: pointer;
}

@media (min-width: 1001px) {
  .footer .arrow {
    bottom: 25px;
  }
  .footer .arrow:hover svg {
    fill: #f37036;
  }
}

.footer .arrow svg {
  width: 20px;
  height: 10px;
  transform: rotateX(180deg);
}

.page_equipment-detail .container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.main-swiper .slider_item.item .item_title, .main-swiper .slider_item.item .item_subtitle, .home-screen-3 .section_title, .home-screen-3 .section_subtitle, .support-screen-1 .support_content .title, .support-screen-1 .support_content .subtitle, .motion-design .section_title, .invitation .section_title {
  text-shadow: 0 10px 15px rgba(0, 0, 0, 0.5), 1px 1px 4px rgba(0, 0, 0, 0.7);
}

body, .Button-std, .home-screen-2 .phone_link, .projects-previews_show-more {
  font-family: 'BebasNeue';
  font-weight: 700;
}

.support-screen-2 .support_accordion-content {
  font-family: 'BebasNeue';
  font-weight: 400;
}

.main-swiper .slider_item.item .item_description, .home-screen-2 .section_description, .home-screen-3 .section_description, .support-screen-1 .support_content .description, .motion-design .section_description, .invitation .section_description, .footer .footer_address, .order_form .order_input, .order_form .order_label, .page_error .error-content .error_message, .support-screen-2 .support_accordion-item.item .item_description, .equipment_detail .detail_description, .invitation .invitation_contact .contact_content, .invitation .invitation_description {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  z-index: 9999999;
  opacity: 1;
  transition: all linear 0.5s;
}

.preloader.deactive {
  opacity: 0;
  transition: all linear 0.5s;
}

.preloader.desactive-next {
  z-index: -1;
}

.footer {
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 80px;
  width: 100%;
  margin-top: auto;
}

.footer.active-blur {
  filter: blur(2px);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

@media (min-width: 768px) {
  .footer {
    padding-bottom: 30px;
  }
}

@media (min-width: 1001px) {
  .footer {
    padding-top: 40px;
  }
}

@media (min-width: 1440px) {
  .footer {
    padding-bottom: 95px;
  }
}

.footer.footer-dark-back {
  background: #000012;
  border-top: 2px solid #353231;
  color: #f8f3f1;
}

.footer.footer-dark-back .footer_social-icon {
  fill: #fff;
}

.footer.footer-white-back {
  background: #fff;
  border-top: 2px solid #f8f3f1;
  color: #282828;
}

.footer.footer-white-back .footer_social-icon {
  fill: #282828;
}

.footer.footer-none-back {
  background: none;
  border: none;
  color: #f8f3f1;
}

.footer.footer-none-back .footer_social-icon {
  fill: #fff;
}

.footer .footer_content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 0;
}

@media (min-width: 768px) {
  .footer .footer_content {
    display: block;
  }
}

@media (min-width: 1440px) {
  .footer .footer_content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.footer .footer_block {
  flex: 1;
}

@media (min-width: 768px) {
  .footer .footer_block.left {
    display: inline-flex;
    flex-direction: column;
    vertical-align: bottom;
    width: 50%;
  }
}

@media (min-width: 1440px) {
  .footer .footer_block.left {
    display: block;
  }
}

.footer .footer_block.center {
  margin-bottom: 30px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .footer .footer_block.center {
    display: none;
    margin: 0;
  }
}

@media (min-width: 1440px) {
  .footer .footer_block.center {
    display: block;
    flex: 1 120px;
    max-width: 120px;
    min-width: 120px;
    margin-bottom: 0;
  }
}

.footer .footer_block.right {
  display: inline-flex;
}

@media (min-width: 768px) {
  .footer .footer_block.right {
    align-items: flex-end;
    flex-direction: row;
    width: 50%;
  }
}

@media (min-width: 1440px) {
  .footer .footer_block.right {
    display: block;
  }
}

.footer .footer_block.right .footer_block-content {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .footer .footer_block.right .footer_block-content {
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
  }
}

@media (min-width: 1440px) {
  .footer .footer_block.right .footer_block-content {
    justify-content: flex-end;
  }
}

.footer .footer_logo {
  width: 25px;
  height: 25px;
  fill: #f37036;
  margin-bottom: 6px;
}

@media (min-width: 1001px) {
  .footer .footer_logo {
    width: 35px;
    height: 35px;
  }
}

.footer .footer_marker {
  display: block;
  margin-bottom: 2px;
  margin-right: 5px;
  width: 19px;
  height: 23px;
  fill: #f37036;
}

@media (min-width: 1440px) {
  .footer .footer_marker {
    margin-bottom: 5px;
    margin-right: 0;
  }
}

.footer .footer_social-icon {
  display: block;
  width: 19px;
  height: 22px;
  margin-right: 2px;
}

.footer .footer_on-map {
  display: flex;
  align-items: flex-end;
  font-size: 16px;
}

@media (min-width: 1001px) {
  .footer .footer_on-map:hover {
    color: #f37036;
  }
}

@media (min-width: 1440px) {
  .footer .footer_on-map {
    align-items: center;
    flex-direction: column;
  }
}

.footer .md_footer_on-map {
  display: none;
  margin-top: 25px;
}

.footer .md_footer_on-map .footer_on-map {
  display: inline-flex;
  width: 33.33%;
}

@media (min-width: 768px) {
  .footer .md_footer_on-map {
    display: flex;
  }
}

@media (min-width: 1440px) {
  .footer .md_footer_on-map {
    display: none;
  }
}

.footer .footer_address {
  font-size: 10px;
  line-height: 16px;
}

@media (min-width: 1001px) {
  .footer .footer_address {
    font-size: 14px;
    line-height: 23px;
  }
}

.footer .footer_question {
  font-size: 24px;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .footer .footer_question {
    flex: 1;
    margin-bottom: 0;
    line-height: 18px;
    text-align: right;
    margin-right: 25px;
  }
}

@media (min-width: 1001px) {
  .footer .footer_question {
    margin-bottom: 0;
    font-size: 36px;
    line-height: 28px;
  }
}

@media (min-width: 1280px) {
  .footer .footer_question {
    font-size: 48px;
    line-height: 29px;
  }
}

.footer .footer_social {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 12px 4px 7px;
  border: 2px solid #f37036;
}

.footer .footer_social:hover {
  background: #f37036;
}

.footer .footer_social:hover .footer_social-icon {
  fill: #fff;
}

.footer .footer_social:hover span {
  color: #fff;
}

.footer .footer_contacts-link {
  font-size: 16px;
  padding-bottom: 6px;
}

.footer .arrow {
  bottom: 20px;
}

.footer .arrow svg {
  fill: #f37036;
}

@media (min-width: 768px) {
  .footer .arrow {
    bottom: 24px;
  }
}

@media (min-width: 1440px) {
  .footer .arrow {
    bottom: 25px;
  }
}

body:not(.page_home) .header {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.97);
}

.header.md_back-light {
  background: rgba(255, 255, 255, 0.97);
}

.header.md_back-light .logo .logo_icon-text {
  fill: #282828;
}

.header.md_back-light .logo:after {
  color: #282828;
}

.header.md_back-light .hamburger .hamburger-line {
  background: #282828;
}

.header.md_back-light .hamburger .hamburger-line:before, .header.md_back-light .hamburger .hamburger-line:after {
  background: #282828;
}

@media (max-width: 1001px) {
  .header.md_back-light .header_menu {
    background: rgba(255, 255, 255, 0.97);
  }
}

.header.md_back-light .header_menu .menu_list {
  border-color: #f8f3f1;
}

.header.md_back-light .header_menu .menu_item.active .menu_link {
  color: #f37036;
}

.header.md_back-light .header_menu .menu_link {
  color: #282828;
}

.header.md_back-light .header_menu .menu_link:hover {
  color: #f37036;
}

.header.active {
  position: fixed;
  background: none;
}

@media (min-width: 768px) {
  .header {
    position: fixed;
  }
}

.header .header_top {
  display: flex;
  align-items: flex-start;
  padding: 20px;
}

@media (min-width: 1001px) {
  .header .header_top {
    padding: 40px 0 40px 40px;
  }
}

.header .logo {
  position: relative;
  z-index: 9999;
}

.header .logo:after {
  content: "©";
  position: absolute;
  right: -11px;
  font-size: 12px;
  top: 8px;
  color: #f8f3f1;
}

.header .logo.swiper-color-dark:after {
  color: #282828;
}

.header .logo.swiper-color-dark.js-fill-light:after {
  color: #f8f3f1;
}

.header .logo .logo_icon-image {
  width: 40px;
  height: 40px;
  fill: #f37036;
}

.header .logo .logo_icon-text {
  display: block;
  position: relative;
  width: 107px;
  height: 25px;
  fill: #f8f3f1;
  margin-left: 8px;
}

.header .logo .logo_icon-text.swiper-color-dark {
  fill: #282828;
}

.header .logo .logo_icon-text.swiper-color-dark.js-fill-light {
  fill: #f8f3f1;
}

.header .logo .logo_link {
  display: flex;
  align-items: flex-end;
}

.header .header_menu {
  flex: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
  height: 0;
  background: rgba(0, 0, 0, 0.95);
}

@media (min-width: 1001px) {
  .header .header_menu {
    position: relative;
    height: auto;
    background: none;
    padding-top: 13px;
    text-align: right;
    overflow: visible;
  }
}

.header .menu .menu_list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding: 80px 0 0 0;
}

@media (min-width: 1001px) {
  .header .menu .menu_list {
    display: inline-flex;
    flex-direction: row;
    padding: 0;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
  }
  .header .menu .menu_list.swiper-color-dark {
    border-color: #f8f3f1;
  }
  .header .menu .menu_list.swiper-color-dark .menu_item.active .menu_link {
    color: #f37036;
  }
  .header .menu .menu_list.swiper-color-dark .menu_link {
    color: #282828;
  }
  .header .menu .menu_list.swiper-color-dark .menu_link:hover {
    color: #f37036;
  }
}

.header .menu .menu_item {
  position: relative;
  width: 100%;
  text-align: right;
  font-size: 0;
}

.header .menu .menu_item.active .menu_link {
  color: #f37036;
}

@media (min-width: 1001px) {
  .header .menu .menu_item {
    width: auto;
    text-align: center;
  }
  .header .menu .menu_item:last-child .menu_link {
    padding-right: 30px;
  }
  .header .menu .menu_item.active:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -3px;
    height: 4px;
    background: #f37036;
  }
}

.header .menu .menu_link {
  color: #f8f3f1;
  font-size: 20px;
  padding: 20px;
}

@media (min-width: 1001px) {
  .header .menu .menu_link {
    padding: 16px 10px 0 10px;
    font-size: 16px;
    line-height: 13px;
  }
  .header .menu .menu_link:hover {
    color: #f37036;
  }
}

/**
 * Swiper 3.4.0
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: October 16, 2016
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.hamburger {
  position: absolute;
  right: 10px;
  top: 28px;
  z-index: 9999;
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  overflow: hidden;
}

@media (min-width: 1001px) {
  .hamburger {
    display: none;
  }
}

.hamburger.swiper-color-dark .hamburger-line {
  background: #282828;
}

.hamburger.swiper-color-dark .hamburger-line:before, .hamburger.swiper-color-dark .hamburger-line:after {
  background: #282828;
}

.hamburger.swiper-color-dark.hamburger-color-light .hamburger-line {
  background: transparent;
}

.hamburger.swiper-color-dark.hamburger-color-light .hamburger-line:before, .hamburger.swiper-color-dark.hamburger-color-light .hamburger-line:after {
  background: #f8f3f1;
}

.hamburger.active .hamburger-line {
  background: none !important;
}

.hamburger.active .hamburger-line:before {
  width: 100%;
  top: 0;
  transform-origin: center;
  transform: rotate(45deg);
}

.hamburger.active .hamburger-line:after {
  width: 100%;
  top: 0;
  transform-origin: center;
  transform: rotate(-45deg);
}

.hamburger-box {
  position: relative;
  width: 20px;
  height: 14px;
}

.hamburger-line {
  position: absolute;
  top: 6px;
  width: 100%;
  height: 2px;
  display: block;
  background: #fff;
}

.hamburger-line.post-active, .hamburger-line.post-active::before, .hamburger-line.post-active::after {
  transition: none;
}

.hamburger-line, .hamburger-line::before, .hamburger-line::after {
  transition: all linear 0.2s;
}

.hamburger-line:before, .hamburger-line:after {
  content: "";
  position: absolute;
  right: 0;
  display: block;
  height: 2px;
  background: #fff;
}

.hamburger-line:before {
  width: 16px;
  top: -6px;
  right: 0;
}

.hamburger-line:after {
  width: 13px;
  top: 6px;
  right: 0;
}

.Breadcrumbs {
  font-size: 0;
}

@media (min-width: 1001px) {
  .Breadcrumbs {
    margin-top: 15px;
  }
}

.Breadcrumbs .Breadcrumb-arrow {
  display: none;
}

@media (min-width: 768px) {
  .Breadcrumbs .Breadcrumb-arrow {
    display: block;
  }
}

.Breadcrumbs_list {
  font-size: 0;
}

@media (min-width: 1001px) {
  .Breadcrumbs_list {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Breadcrumbs_li {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  vertical-align: top;
  color: #959595;
}

@media (min-width: 768px) {
  .Breadcrumbs_li {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .Breadcrumbs_li.mod_mob {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .Breadcrumbs_li.mod_mob {
    display: none;
  }
}

.Breadcrumbs_link {
  color: inherit;
  font-size: 16px;
  transition: color ease-out 0.4s;
  line-height: 20px;
}

.Breadcrumbs_link.active {
  color: #282828;
  cursor: default;
}

.Breadcrumbs_link:not(.active):hover {
  color: #f37036;
}

.Breadcrumb-arrow {
  position: absolute;
  right: -3px;
  top: 5px;
  height: 7px;
  width: 7px;
  fill: #959595;
  transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  transform-origin: center center;
  transition: fill ease-out 0.4s;
}

.Breadcrumbs_link:not(.active):hover .Breadcrumb-arrow {
  fill: #f37036;
}

.Breadcrumbs_li.mod_mob .Breadcrumb-arrow {
  left: 9px;
  right: auto;
  transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
}

.Breadcrumbs_li:last-of-type .Breadcrumb-arrow {
  display: none;
}

.breadcrumbs-secondary_wrapper {
  position: relative;
  margin-bottom: 25px;
}

.breadcrumbs-secondary_wrapper.mod_underline::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: -7px;
  display: block;
  width: 16000px;
  height: 2px;
  background-color: #f8f3f1;
}

@media (max-width: 767px) {
  .breadcrumbs-secondary_list {
    white-space: nowrap;
    overflow: auto;
  }
}

.breadcrumbs-secondary_li {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  color: #959595;
  padding-right: 12px;
}

.breadcrumbs-secondary_li.Active {
  color: #282828;
}

.breadcrumbs-secondary_li.Active .breadcrumbs-secondary_link {
  color: inherit;
  cursor: default;
}

.breadcrumbs-secondary_link {
  color: inherit;
  transition: color ease-out 0.4s;
  cursor: pointer;
}

.breadcrumbs-secondary_link:hover {
  color: #f37036;
}

.interactive {
  max-width: 320px;
}

.interactive_pre-title {
  font-size: 20px;
  color: #f37036;
}

.interactive_title {
  font-size: 24px;
}

@media (min-width: 1001px) {
  .interactive_title {
    font-size: 29px;
    line-height: 1.1;
  }
}

.interactive_button {
  margin-top: 20px;
}

@media (min-width: 1001px) {
  .interactive_button {
    margin-top: 8px;
  }
}

.Section_heading {
  position: relative;
  padding-bottom: 2px;
}

.Section_heading.mod_underline::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: -2px;
  display: block;
  width: 16000px;
  height: 2px;
  background-color: #f8f3f1;
}

.Section_heading.mod_border-bottom {
  border-bottom: 2px solid #f8f3f1;
}

.Section_title-info {
  font-size: 16px;
  color: #f37036;
}

@media (min-width: 1001px) {
  .Section_title-info {
    font-size: 27px;
  }
}

.Section_title {
  color: inherit;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: -.004em;
}

@media (min-width: 1001px) {
  .Section_title {
    font-size: 47px;
  }
}

.menu_sublist {
  display: none;
}

.left_menu.menu {
  font-size: 16px;
  color: #282828;
}

.left_menu.menu .menu_item {
  margin-bottom: 15px;
}

@media (min-width: 1001px) {
  .left_menu.menu .menu_list {
    padding-left: 47px;
  }
}

.left_menu.menu .menu_item-title {
  cursor: pointer;
  line-height: 20px;
}

.left_menu.menu .menu_item-title:hover, .left_menu.menu .menu_item-title.active {
  color: #f37036;
}

.left_menu.menu .menu_sublink {
  position: relative;
  display: block;
  padding-left: 30px;
  line-height: 22px;
}

.left_menu.menu .menu_sublink:hover, .left_menu.menu .menu_sublink.active {
  color: #f37036;
}

.left_menu.menu .menu_sublink:hover:before {
  background: #f37036;
}

.left_menu.menu .menu_sublink:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 22px;
  height: 2px;
  background: #282828;
}

.equipment-swiper-wrapper {
  position: relative;
  width: 100%;
}

@media (min-width: 1001px) {
  .equipment-swiper-wrapper .equipment-pagination {
    display: none;
  }
}

.equipment-swiper-wrapper .equipment-pagination.swiper-pagination-bullets {
  bottom: 0;
}

.equipment-swiper-wrapper .equipment-pagination .swiper-pagination-bullet {
  border: 2px solid #282828;
}

.equipment-swiper-wrapper .equipment-pagination .swiper-pagination-bullet-active {
  background: #282828;
}

.equipment-swiper {
  height: auto;
  width: 100%;
  padding-bottom: 35px;
}

@media (min-width: 375px) {
  .equipment-swiper {
    padding-bottom: 40px;
  }
}

@media (min-width: 1001px) {
  .equipment-swiper {
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 0;
    overflow: visible;
  }
}

@media (min-width: 1440px) {
  .equipment-swiper {
    padding-left: 70px;
    padding-right: 70px;
  }
}

.equipment-swiper .swiper-wrapper {
  width: 250px;
  height: auto;
  margin: 0 auto;
}

@media (min-width: 1001px) {
  .equipment-swiper .swiper-wrapper {
    width: 100%;
    display: block;
    font-size: 0;
  }
}

@media (max-width: 1000px) {
  .equipment-swiper.swiper-container-3d .swiper-slide {
    transform: translate3d(0px, 10px, -14.6364px) rotateX(0deg) rotateY(0deg) !important;
  }
  .equipment-swiper.swiper-container-3d .swiper-slide .item {
    border: 2px solid #f8f3f1;
  }
  .equipment-swiper.swiper-container-3d .swiper-slide.swiper-slide-active {
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) !important;
  }
  .equipment-swiper.swiper-container-3d .swiper-slide.swiper-slide-active .item {
    border: 2px solid #f37036;
  }
}

.equipment-swiper .slider_item {
  position: relative;
}

@media (min-width: 1001px) {
  .equipment-swiper .slider_item {
    display: none;
    vertical-align: top;
    width: 25% !important;
    padding: 0 10px;
    margin-bottom: 20px;
  }
  .equipment-swiper .slider_item:nth-of-type(1) {
    display: inline-block;
  }
  .equipment-swiper .slider_item:nth-of-type(2) {
    display: inline-block;
  }
  .equipment-swiper .slider_item:nth-of-type(3) {
    display: inline-block;
  }
  .equipment-swiper .slider_item:nth-of-type(4) {
    display: inline-block;
  }
}

@media (min-width: 1440px) {
  .equipment-swiper .slider_item {
    padding: 0 15px;
  }
}

#Google-Map {
  height: 100%;
}

.order {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  visibility: hidden;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.97);
  opacity: 0;
}

.order.visible {
  opacity: 1;
  visibility: visible;
}

.order .order_close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .order .order_close {
    right: 30px;
    top: 30px;
  }
  .order .order_close:hover svg {
    fill: #f37036;
  }
}

.order .order_close svg {
  width: 22px;
  height: 22px;
  stroke-width: 2px;
  fill: #fff;
}

.order .order_container {
  position: relative;
  max-width: 690px;
  width: 100%;
  color: #f8f3f1;
}

.order .order_title {
  color: #f37036;
  font-size: 27px;
  text-transform: uppercase;
  text-align: center;
}

@media (min-width: 768px) {
  .order .order_title {
    font-size: 54px;
  }
}

.order .order_subtitle {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .order .order_subtitle {
    font-size: 43px;
    margin-bottom: 30px;
  }
}

.order_form {
  max-width: 385px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .order_form {
    padding: 0;
  }
}

.order_form .order_field {
  position: relative;
  width: 100%;
  border-bottom: 2px solid #f8f3f1;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .order_form .order_field {
    margin-bottom: 35px;
  }
}

.order_form .order_field.active {
  border-bottom: 2px solid #fff;
}

.order_form .order_input {
  display: block;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  border: none;
  background: none;
  text-align: left;
}

.order_form .order_input:focus {
  outline: none;
}

.order_form .order_label {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 9px 10px 0 10px;
  color: #d4cfcd;
  font-size: 16px;
  transform: translate3d(0, 0, 0) scale(1);
  text-align: left;
}

.order_form .order_label.active {
  top: -20px;
  font-size: 12px;
}

.order_form .order_form-button {
  width: 100%;
}

@media (min-width: 768px) {
  .order_form .order_form-button {
    margin-top: 15px;
  }
}

.order_form .input_file input {
  display: none;
}

.order_form .input_file label {
  display: block;
  width: 100%;
}

.order_response {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  visibility: hidden;
  z-index: 1000;
  opacity: 0;
  background: rgba(0, 0, 0, 0.95);
  color: #fff;
}

.order_response.visible {
  opacity: 1;
  visibility: visible;
}

.order_response .response {
  margin-bottom: 35px;
}

.order_response .response h3 {
  font-size: 25px;
}

.order_response .response p {
  font-size: 20px;
}

.order_response .order_response-text > * {
  display: block;
  margin: 5px 0;
  font-size: 18px;
}

.js-error {
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
  text-align: center;
}

.order-button {
  cursor: pointer;
}

.TZ .order_form-button {
  margin-top: 15px;
}

.TZ .dz-default.dz-message {
  width: 100%;
}

.TZ .dz-default.dz-message span {
  width: 100%;
  padding: 10px;
  background: red;
}

.TZ .dz-image {
  display: none;
}

.TZ .dz-default.dz-message span {
  margin-bottom: 15px;
  padding: 24px 10px;
  background: none;
  border: 2px solid #f8f3f1;
  text-align: center;
  color: #d4cfcd;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  transition: ease-out 0.4s;
}

.TZ .dz-default.dz-message span i {
  display: block;
  font-style: normal;
  font-size: 12px;
  margin-top: 3px;
}

.TZ .dz-progress, .TZ .dz-error-message, .TZ .dz-size, .TZ .dz-success-mark, .TZ .dz-error-mark {
  display: none;
}

.TZ .dz-drag-hover .dz-default.dz-message span, .TZ .dz-started .dz-default.dz-message span {
  border: 2px solid #fff;
  color: #fff;
}

.TZ .dz-filename {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 4px;
  color: #d4cfcd;
}

.addtocalendar {
  margin-top: 10px;
  border: none !important;
}

.addtocalendar .atcb-list {
  width: auto;
  left: -2px;
  right: -2px;
  top: 43px;
  background: none;
}

.addtocalendar .atcb-list .atcb-item {
  background: none;
  text-align: center;
}

.addtocalendar .atcb-list .atcb-item-link {
  padding: 10px;
}

.addtocalendar .atcb-list .atcb-item-link:hover {
  background: #f37036;
}

.meeting .order_response .js-order_close {
  margin-top: 16px;
}

/*! jQuery UI - v1.12.1 - 2017-08-28
* http://jqueryui.com
* Includes: core.css, datepicker.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?scope=&folderName=ui-lightness&cornerRadiusShadow=5px&offsetLeftShadow=-5px&offsetTopShadow=-5px&thicknessShadow=5px&opacityShadow=20&bgImgOpacityShadow=10&bgTextureShadow=flat&bgColorShadow=000000&opacityOverlay=50&bgImgOpacityOverlay=20&bgTextureOverlay=diagonals_thick&bgColorOverlay=666666&iconColorError=ffd27a&fcError=ffffff&borderColorError=cd0a0a&bgImgOpacityError=18&bgTextureError=diagonals_thick&bgColorError=b81900&iconColorHighlight=228ef1&fcHighlight=363636&borderColorHighlight=fed22f&bgImgOpacityHighlight=75&bgTextureHighlight=highlight_soft&bgColorHighlight=ffe45c&iconColorActive=ef8c08&fcActive=eb8f00&borderColorActive=fbd850&bgImgOpacityActive=65&bgTextureActive=glass&bgColorActive=ffffff&iconColorHover=ef8c08&fcHover=c77405&borderColorHover=fbcb09&bgImgOpacityHover=100&bgTextureHover=glass&bgColorHover=fdf5ce&iconColorDefault=ef8c08&fcDefault=1c94c4&borderColorDefault=cccccc&bgImgOpacityDefault=100&bgTextureDefault=glass&bgColorDefault=f6f6f6&iconColorContent=222222&fcContent=333333&borderColorContent=dddddd&bgImgOpacityContent=100&bgTextureContent=highlight_soft&bgColorContent=eeeeee&iconColorHeader=ffffff&fcHeader=ffffff&borderColorHeader=e78f08&bgImgOpacityHeader=35&bgTextureHeader=gloss_wave&bgColorHeader=f6a828&cornerRadius=4px&fsDefault=1.1em&fwDefault=bold&ffDefault=Trebuchet%20MS%2CTahoma%2CVerdana%2CArial%2Csans-serif
* Copyright jQuery Foundation and other contributors; Licensed MIT */
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.ui-front {
  z-index: 100;
}

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none;
}

.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block;
}

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px;
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 45%;
}

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em;
}

.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
}

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}

.ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  left: .5em;
  top: .3em;
}

.ui-widget {
  font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
  font-size: 1.1em;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
  font-size: 1em;
}

.ui-widget.ui-widget-content {
  border: 1px solid #ccc;
}

.ui-widget-content {
  border: 1px solid #ddd;
  background: #eee url("/img/ui-bg_highlight-soft_100_eeeeee_1x100.png") 50% top repeat-x;
  color: #333;
}

.ui-widget-content a {
  color: #333;
}

.ui-widget-header {
  border: 1px solid #e78f08;
  background: #f37036 url("/img/ui-bg_gloss-wave_35_f6a828_500x100.png") 50% 50% repeat-x;
  color: #fff;
  font-weight: bold;
}

.ui-widget-header a {
  color: #fff;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  border: 1px solid #ccc;
  background: #f6f6f6 url("/img/ui-bg_glass_100_f6f6f6_1x400.png") 50% 50% repeat-x;
  font-weight: bold;
  color: #1c94c4;
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
  color: #1c94c4;
  text-decoration: none;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
  border: 1px solid #fbcb09;
  background: #fdf5ce url("/img/ui-bg_glass_100_fdf5ce_1x400.png") 50% 50% repeat-x;
  font-weight: bold;
  color: #c77405;
}

.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited, .ui-state-focus a, .ui-state-focus a:hover, .ui-state-focus a:link, .ui-state-focus a:visited, a.ui-button:hover, a.ui-button:focus {
  color: #c77405;
  text-decoration: none;
}

.ui-visual-focus {
  box-shadow: 0 0 3px 1px #5e9ed6;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  border: 1px solid #fbd850;
  background: #fff;
  font-weight: bold;
  color: #f37036;
}

.ui-icon-background, .ui-state-active .ui-icon-background {
  border: #fbd850;
  background-color: #eb8f00;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
  color: #eb8f00;
  text-decoration: none;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #f37036;
  background: #f37036;
  color: #fff;
}

.ui-state-checked {
  border: 1px solid #fed22f;
  background: #ffe45c;
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
  color: #363636;
}

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
  border: 1px solid #cd0a0a;
  background: #b81900 url("/img/ui-bg_diagonals-thick_18_b81900_40x40.png") 50% 50% repeat;
  color: #fff;
}

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
  color: #fff;
}

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
  color: #fff;
}

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
  font-weight: bold;
}

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  font-weight: normal;
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none;
}

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35);
}

.ui-icon {
  width: 16px;
  height: 16px;
}

.ui-icon, .ui-widget-content .ui-icon {
  background-image: url("/img/ui-icons_222222_256x240.png");
}

.ui-widget-header .ui-icon {
  background-image: url("/img/ui-icons_ffffff_256x240.png");
}

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon, .ui-button:hover .ui-icon, .ui-button:focus .ui-icon {
  background-image: url("/img/ui-icons_ef8c08_256x240.png");
}

.ui-state-active .ui-icon, .ui-button:active .ui-icon {
  background-image: url("/img/ui-icons_ef8c08_256x240.png");
}

.ui-state-highlight .ui-icon, .ui-button .ui-state-highlight.ui-icon {
  background-image: url("/img/ui-icons_228ef1_256x240.png");
}

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
  background-image: url("/img/ui-icons_ffd27a_256x240.png");
}

.ui-button .ui-icon {
  background-image: url("/img/ui-icons_ef8c08_256x240.png");
}

.ui-icon-blank {
  background-position: 16px 16px;
}

.ui-icon-caret-1-n {
  background-position: 0 0;
}

.ui-icon-caret-1-ne {
  background-position: -16px 0;
}

.ui-icon-caret-1-e {
  background-position: -32px 0;
}

.ui-icon-caret-1-se {
  background-position: -48px 0;
}

.ui-icon-caret-1-s {
  background-position: -65px 0;
}

.ui-icon-caret-1-sw {
  background-position: -80px 0;
}

.ui-icon-caret-1-w {
  background-position: -96px 0;
}

.ui-icon-caret-1-nw {
  background-position: -112px 0;
}

.ui-icon-caret-2-n-s {
  background-position: -128px 0;
}

.ui-icon-caret-2-e-w {
  background-position: -144px 0;
}

.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
  background-position: -65px -16px;
}

.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}

.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}

.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}

.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}

.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}

.ui-icon-arrow-1-s {
  background-position: -65px -32px;
}

.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}

.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}

.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}

.ui-icon-arrowthick-1-n {
  background-position: 1px -48px;
}

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}

.ui-icon-arrow-4 {
  background-position: 0 -80px;
}

.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}

.ui-icon-extlink {
  background-position: -32px -80px;
}

.ui-icon-newwin {
  background-position: -48px -80px;
}

.ui-icon-refresh {
  background-position: -64px -80px;
}

.ui-icon-shuffle {
  background-position: -80px -80px;
}

.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}

.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}

.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}

.ui-icon-folder-open {
  background-position: -16px -96px;
}

.ui-icon-document {
  background-position: -32px -96px;
}

.ui-icon-document-b {
  background-position: -48px -96px;
}

.ui-icon-note {
  background-position: -64px -96px;
}

.ui-icon-mail-closed {
  background-position: -80px -96px;
}

.ui-icon-mail-open {
  background-position: -96px -96px;
}

.ui-icon-suitcase {
  background-position: -112px -96px;
}

.ui-icon-comment {
  background-position: -128px -96px;
}

.ui-icon-person {
  background-position: -144px -96px;
}

.ui-icon-print {
  background-position: -160px -96px;
}

.ui-icon-trash {
  background-position: -176px -96px;
}

.ui-icon-locked {
  background-position: -192px -96px;
}

.ui-icon-unlocked {
  background-position: -208px -96px;
}

.ui-icon-bookmark {
  background-position: -224px -96px;
}

.ui-icon-tag {
  background-position: -240px -96px;
}

.ui-icon-home {
  background-position: 0 -112px;
}

.ui-icon-flag {
  background-position: -16px -112px;
}

.ui-icon-calendar {
  background-position: -32px -112px;
}

.ui-icon-cart {
  background-position: -48px -112px;
}

.ui-icon-pencil {
  background-position: -64px -112px;
}

.ui-icon-clock {
  background-position: -80px -112px;
}

.ui-icon-disk {
  background-position: -96px -112px;
}

.ui-icon-calculator {
  background-position: -112px -112px;
}

.ui-icon-zoomin {
  background-position: -128px -112px;
}

.ui-icon-zoomout {
  background-position: -144px -112px;
}

.ui-icon-search {
  background-position: -160px -112px;
}

.ui-icon-wrench {
  background-position: -176px -112px;
}

.ui-icon-gear {
  background-position: -192px -112px;
}

.ui-icon-heart {
  background-position: -208px -112px;
}

.ui-icon-star {
  background-position: -224px -112px;
}

.ui-icon-link {
  background-position: -240px -112px;
}

.ui-icon-cancel {
  background-position: 0 -128px;
}

.ui-icon-plus {
  background-position: -16px -128px;
}

.ui-icon-plusthick {
  background-position: -32px -128px;
}

.ui-icon-minus {
  background-position: -48px -128px;
}

.ui-icon-minusthick {
  background-position: -64px -128px;
}

.ui-icon-close {
  background-position: -80px -128px;
}

.ui-icon-closethick {
  background-position: -96px -128px;
}

.ui-icon-key {
  background-position: -112px -128px;
}

.ui-icon-lightbulb {
  background-position: -128px -128px;
}

.ui-icon-scissors {
  background-position: -144px -128px;
}

.ui-icon-clipboard {
  background-position: -160px -128px;
}

.ui-icon-copy {
  background-position: -176px -128px;
}

.ui-icon-contact {
  background-position: -192px -128px;
}

.ui-icon-image {
  background-position: -208px -128px;
}

.ui-icon-video {
  background-position: -224px -128px;
}

.ui-icon-script {
  background-position: -240px -128px;
}

.ui-icon-alert {
  background-position: 0 -144px;
}

.ui-icon-info {
  background-position: -16px -144px;
}

.ui-icon-notice {
  background-position: -32px -144px;
}

.ui-icon-help {
  background-position: -48px -144px;
}

.ui-icon-check {
  background-position: -64px -144px;
}

.ui-icon-bullet {
  background-position: -80px -144px;
}

.ui-icon-radio-on {
  background-position: -96px -144px;
}

.ui-icon-radio-off {
  background-position: -112px -144px;
}

.ui-icon-pin-w {
  background-position: -128px -144px;
}

.ui-icon-pin-s {
  background-position: -144px -144px;
}

.ui-icon-play {
  background-position: 0 -160px;
}

.ui-icon-pause {
  background-position: -16px -160px;
}

.ui-icon-seek-next {
  background-position: -32px -160px;
}

.ui-icon-seek-prev {
  background-position: -48px -160px;
}

.ui-icon-seek-end {
  background-position: -64px -160px;
}

.ui-icon-seek-start {
  background-position: -80px -160px;
}

.ui-icon-seek-first {
  background-position: -80px -160px;
}

.ui-icon-stop {
  background-position: -96px -160px;
}

.ui-icon-eject {
  background-position: -112px -160px;
}

.ui-icon-volume-off {
  background-position: -128px -160px;
}

.ui-icon-volume-on {
  background-position: -144px -160px;
}

.ui-icon-power {
  background-position: 0 -176px;
}

.ui-icon-signal-diag {
  background-position: -16px -176px;
}

.ui-icon-signal {
  background-position: -32px -176px;
}

.ui-icon-battery-0 {
  background-position: -48px -176px;
}

.ui-icon-battery-1 {
  background-position: -64px -176px;
}

.ui-icon-battery-2 {
  background-position: -80px -176px;
}

.ui-icon-battery-3 {
  background-position: -96px -176px;
}

.ui-icon-circle-plus {
  background-position: 0 -192px;
}

.ui-icon-circle-minus {
  background-position: -16px -192px;
}

.ui-icon-circle-close {
  background-position: -32px -192px;
}

.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}

.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}

.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}

.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}

.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}

.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}

.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}

.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}

.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}

.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}

.ui-icon-circle-check {
  background-position: -208px -192px;
}

.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}

.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}

.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}

.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}

.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}

.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 4px;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 4px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 4px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 4px;
}

.ui-widget-overlay {
  background: #666 url("/img/ui-bg_diagonals-thick_20_666666_40x40.png") 50% 50% repeat;
  opacity: .5;
  filter: Alpha(Opacity=50);
}

.ui-widget-shadow {
  -webkit-box-shadow: -5px -5px 5px #000;
  box-shadow: -5px -5px 5px #000;
}

.ui-widget.ui-widget-content {
  max-width: 385px;
  width: 100%;
}

@media (max-width: 385px) {
  .ui-widget.ui-widget-content {
    left: 0 !important;
    right: 0 !important;
    max-width: 100%;
  }
}

.page_error {
  height: 100%;
}

.page_error .container {
  display: flex;
  flex-direction: column;
}

.page_error .container .footer {
  border-top: none;
}

.page_error .container .header {
  position: relative;
  background: #000012;
}

.page_error main {
  display: flex;
  align-items: stretch;
  background: #000012;
  height: auto;
  flex: 1;
}

.page_error .error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  text-align: center;
  height: 300px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .page_error .error-content {
    height: 500px;
  }
}

@media (min-width: 1001px) {
  .page_error .error-content {
    height: 100%;
  }
}

.page_error .error-content .error_title {
  font-size: 100px;
  color: #f37036;
}

@media (min-width: 1001px) {
  .page_error .error-content .error_title {
    font-size: 300px;
    line-height: 250px;
  }
}

@media (min-width: 1024px) and (max-height: 750px) {
  .page_error .error-content .error_title {
    font-size: 80px;
    line-height: normal;
  }
}

.page_error .error-content .error_button {
  width: 225px;
  margin-top: 30px;
}

.page_error .error-content .error_message {
  font-size: 20px;
}

.home-screen-1 {
  position: relative;
  background: #000000;
  height: 600px;
}

@media (min-width: 768px) {
  .home-screen-1 {
    height: 100%;
  }
}

.home-screen-1.md_color-light {
  color: #f8f3f1;
}

.home-screen-1 .section_title {
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 9;
  text-transform: uppercase;
  padding: 0 20px;
  color: #282828;
}

@media (min-width: 1001px) {
  .home-screen-1 .section_title {
    top: 100px;
    padding: 0 40px;
  }
}

.home-screen-1 .section_title > span {
  display: block;
  font-size: 0;
  margin-bottom: 2px;
}

.home-screen-1 .section_title > span > span {
  display: inline-block;
  font-size: 16px;
  line-height: 12px;
  background: #f8f3f1;
  padding: 5px 4px 2px 4px;
}

.home-screen-1 .top-gradient {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;
  height: 28%;
  background: -moz-linear-gradient(top, black 0%, black 62%, rgba(0, 0, 0, 0.73) 78%, transparent 100%);
  background: -webkit-linear-gradient(top, black 0%, black 62%, rgba(0, 0, 0, 0.73) 78%, transparent 100%);
  background: linear-gradient(to bottom, black 0%, black 62%, rgba(0, 0, 0, 0.73) 78%, transparent 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
}

@media (min-width: 1001px) {
  .home-screen-1 .top-gradient {
    background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.7) 26%, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0.5) 66%, rgba(0, 0, 0, 0.01) 99%, transparent 100%);
    background: -webkit-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.7) 26%, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0.5) 66%, rgba(0, 0, 0, 0.01) 99%, transparent 100%);
    background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.7) 26%, rgba(0, 0, 0, 0.6) 47%, rgba(0, 0, 0, 0.5) 66%, rgba(0, 0, 0, 0.01) 99%, transparent 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
  }
}

.home-screen-1 .bottom-gradient {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  height: 59%;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.73) 13%, black 24%, black 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.73) 13%, black 24%, black 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.73) 13%, black 24%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
}

@media (min-width: 1001px) {
  .home-screen-1 .bottom-gradient {
    height: 40%;
  }
}

.home-screen-1 .arrow {
  display: none;
}

@media (min-width: 768px) {
  .home-screen-1 .arrow {
    display: block;
  }
}

.home-screen-1 .item_button {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 92px;
  text-align: center;
}

@media (min-width: 768px) {
  .home-screen-1 .item_button {
    text-align: left;
    bottom: 12vh;
  }
}

.home-screen-1 .item_link {
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  border: 2px solid #f37036;
  padding: 12px 56px;
  cursor: pointer;
  background: none;
  z-index: 9;
}

@media (min-width: 768px) {
  .home-screen-1 .item_link:hover {
    background: #f37036;
  }
  .home-screen-1 .item_link:hover span {
    left: -15px;
    transition: all linear 0.2s;
  }
  .home-screen-1 .item_link:hover svg {
    right: -35px;
    opacity: 1;
    transition: all linear 0.2s;
  }
}

.home-screen-1 .item_link span {
  font-family: 'BebasNeue';
  font-weight: 700;
  position: relative;
  left: 0;
  line-height: 15px;
  padding-top: 2px;
}

.home-screen-1 .item_link svg {
  position: absolute;
  right: 0;
  top: 1px;
  display: none;
  width: 25px;
  height: 15px;
  fill: #f8f3f1;
  opacity: 0;
}

@media (min-width: 768px) {
  .home-screen-1 .item_link svg {
    display: block;
  }
}

@media (min-width: 768px) {
  .home-screen-1 .item_link {
    margin-left: 50px;
  }
}

@media (min-width: 1001px) {
  .home-screen-1 .item_link {
    margin-left: 85px;
  }
}

.main-swiper {
  height: 100%;
}

.main-swiper .slider_item.item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}

.main-swiper .slider_item.item .item_content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 20px 20px 140px 20px;
}

@media (min-width: 768px) {
  .main-swiper .slider_item.item .item_content {
    padding: 20px 20px 17vh 20px;
  }
}

@media (min-width: 1001px) {
  .main-swiper .slider_item.item .item_content {
    padding: 40px 40px 17vh 40px;
  }
}

.main-swiper .slider_item.item .item_title {
  margin-bottom: 2px;
}

.main-swiper .slider_item.item .item_description {
  margin: 10px 0 15px 0;
}

@media (min-width: 1001px) {
  .main-swiper .slider_item.item .item_description {
    margin: 8px 0 20px 0;
    height: auto;
  }
}

@media (min-width: 1440px) {
  .main-swiper .slider_item.item .item_description {
    margin: 0 0 20px 0;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 57px;
}

@media (min-width: 768px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 9%;
  }
}

@media (min-width: 1001px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 8%;
  }
}

.home-screen-2 {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .home-screen-2 {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
  }
}

.home-screen-2 .section_content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.home-screen-2 .section_titles {
  margin-bottom: 10px;
}

.home-screen-2 .section_description {
  color: #282828;
  margin: 5px 0 20px 0;
}

.home-screen-2 .section_description > p:nth-child(2) {
  display: none;
}

@media (min-width: 768px) {
  .home-screen-2 .section_description > p:nth-child(2) {
    display: block;
  }
}

.home-screen-2 .section_description:before {
  background: #282828;
}

.home-screen-2 .phone_link {
  position: relative;
  top: 1px;
  color: #f37036;
  font-size: 16px;
  line-height: 15px;
}

@media (min-width: 1001px) {
  .home-screen-2 .phone_link {
    font-size: 16px;
    line-height: 22px;
    top: 0;
  }
}

@media (min-width: 1440px) {
  .home-screen-2 .phone_link {
    font-size: 20px;
  }
}

.home-screen-2 .arrow {
  display: none;
}

@media (min-width: 768px) {
  .home-screen-2 .arrow {
    display: block;
  }
}

.home-screen-3 {
  position: relative;
  background: #000012;
}

@media (min-width: 768px) {
  .home-screen-3 {
    height: 100%;
  }
}

.home-screen-3.md_color-light {
  color: #f8f3f1;
}

.home-screen-3 .section_back {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url("/../img/home-screen-3-back.jpg");
  background-size: cover;
  background-position: center top;
  opacity: 0.4;
}

.home-screen-3 .section_content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.home-screen-3 .section_titles {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 60px 20px 60px 20px;
}

@media (min-width: 768px) {
  .home-screen-3 .section_titles {
    height: auto;
    justify-content: center;
    padding: 40px 20px 60px 20px;
  }
}

@media (min-width: 1001px) {
  .home-screen-3 .section_titles {
    padding: 100px 80px 40px 40px;
  }
}

.home-screen-3 .section_description {
  margin: 5px 0 20px 0;
}

.home-screen-3 .section_description:before {
  background: #f8f3f1;
}

.home-screen-3 .section_link {
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  border: 2px solid #f37036;
  padding: 12px 56px;
  cursor: pointer;
  background: none;
  margin-left: 30px;
}

@media (min-width: 768px) {
  .home-screen-3 .section_link:hover {
    background: #f37036;
  }
  .home-screen-3 .section_link:hover span {
    left: -20px;
    transition: all linear 0.2s;
  }
  .home-screen-3 .section_link:hover svg {
    right: -35px;
    opacity: 1;
    transition: all linear 0.2s;
  }
}

.home-screen-3 .section_link span {
  font-family: 'BebasNeue';
  font-weight: 700;
  position: relative;
  left: 0;
  line-height: 15px;
  padding-top: 2px;
}

.home-screen-3 .section_link svg {
  position: absolute;
  right: 0;
  top: 1px;
  display: none;
  width: 25px;
  height: 15px;
  fill: #f8f3f1;
  opacity: 0;
}

@media (min-width: 768px) {
  .home-screen-3 .section_link svg {
    display: block;
  }
}

@media (min-width: 1001px) {
  .home-screen-3 .section_link {
    margin-left: 45px;
  }
}

@media (min-width: 768px) {
  .page_home {
    overflow: hidden;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .page_home .header {
    background: none;
  }
}

.big-vertical-swiper {
  height: 100%;
  overflow: visible;
}

@media (min-width: 1001px) {
  .big-vertical-swiper {
    overflow: hidden;
  }
}

.big-vertical-swiper .home-swiper-wrapper {
  display: block;
}

@media (min-width: 1001px) {
  .big-vertical-swiper .home-swiper-wrapper {
    display: flex;
  }
}

.big-vertical-swiper .swiper-pagination.vertical-pagination {
  display: none;
  right: 25px;
  counter-reset: item-paginate;
}

@media (min-width: 768px) {
  .big-vertical-swiper .swiper-pagination.vertical-pagination {
    display: block;
  }
  .big-vertical-swiper .swiper-pagination.vertical-pagination.swiper-color-dark .swiper-pagination-bullet:before {
    color: #282828;
  }
  .big-vertical-swiper .swiper-pagination.vertical-pagination.swiper-color-dark .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    color: #fff;
  }
}

.big-vertical-swiper .swiper-pagination.vertical-pagination .swiper-pagination-bullet {
  position: relative;
  width: 25px;
  height: 25px;
  background: none;
  opacity: 1;
  border-radius: 0;
  margin: 10px 0;
}

.big-vertical-swiper .swiper-pagination.vertical-pagination .swiper-pagination-bullet:before {
  content: counter(item-paginate);
  counter-increment: item-paginate;
  position: absolute;
  top: 8px;
  left: 9px;
  font-size: 16px;
  line-height: 12px;
  color: #fff;
}

.big-vertical-swiper .swiper-pagination.vertical-pagination .swiper-pagination-bullet:hover:not(.swiper-pagination-bullet-active):before {
  color: #f37036;
}

.big-vertical-swiper .swiper-pagination.vertical-pagination .swiper-pagination-bullet-active {
  background: #f37036;
}

.mod_list_projects .tab-block:nth-child(n):not(:first-of-type) {
  display: none;
}

.projects-preview {
  background-color: #0d0d27;
}

.projects-slide.swiper-slide-active, .projects-slide.swiper-slide-duplicate-active {
  box-shadow: 0 7px 13px 0 rgba(29, 29, 27, 0.15);
}

@media (min-width: 1001px) {
  .projects-slide.swiper-slide-active, .projects-slide.swiper-slide-duplicate-active {
    box-shadow: 0 15px 25px 0 rgba(29, 29, 27, 0.15);
  }
}

.projects {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.projects.mod_completed {
  margin-bottom: 30px;
}

.projects .tab-block .Section_heading {
  display: none;
  padding: 0 20px;
}

@media (min-width: 1001px) {
  .projects .tab-block .Section_heading {
    padding: 0 40px;
  }
}

.projects .tab-block .Section_heading.active {
  display: block;
}

.projects_heading {
  color: #282828;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1001px) {
  .projects_heading {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .projects.mod_latest .projects_heading {
    padding-top: 31px;
  }
}

@media (min-width: 768px) {
  .projects.mod_completed .projects_heading {
    margin-bottom: 4px;
  }
}

.projects-slider {
  margin: 20px 0;
}

@media (min-width: 768px) {
  .projects-slider {
    margin: 30px 0;
  }
}

.projects-slider_container {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 13px;
}

@media (min-width: 768px) {
  .projects-slider_container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1001px) {
  .projects-slider_container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.projects-slide {
  max-width: calc(100% - 40px);
  max-width: -webkit-calc(100% - 40px);
  transition: all linear .3s;
}

@media (min-width: 768px) {
  .projects-slide {
    max-width: 36%;
  }
}

.projects-previews_list {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  padding-top: 20px;
}

@media (min-width: 768px) {
  .projects-previews_list {
    font-size: 0;
  }
}

@media (min-width: 1001px) {
  .projects-previews_list {
    padding: 15px 20px 20px 20px;
  }
}

.projects-previews_li {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .projects-previews_li:nth-child(n + 5) {
    display: none;
  }
}

@media (min-width: 768px) {
  .projects-previews_li {
    display: inline-block;
    vertical-align: top;
    width: 33.3333333333%;
  }
}

@media (min-width: 1001px) {
  .projects-previews_li {
    width: 20%;
    padding-bottom: 40px;
  }
}

@media (max-width: 1001px) {
  .projects-previews_li.visible {
    display: block;
  }
}

.projects-previews_show-more {
  display: table;
  margin: 4px auto 24px;
  text-transform: uppercase;
  color: #f37036;
  font-size: 16px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .projects-previews_show-more {
    display: none;
  }
}

.projects-previews_show-more.disabled {
  color: #959595;
  display: none;
}

.projects-previews_show-more-arrow {
  width: 7px;
  height: 7px;
  margin-right: 3px;
  transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-45deg);
  transform-origin: center center;
  fill: #f37036;
  display: inline-block;
  vertical-align: middle;
}

.projects-previews_show-more.disabled {
  fill: #959595;
}

.projects-preview {
  position: relative;
  padding-bottom: 56%;
  color: #ffffff;
}

@media (min-width: 768px) {
  .projects-preview:hover::before {
    background-color: rgba(0, 0, 18, 0.45);
  }
}

.projects-preview::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 18, 0.45);
  transition: background linear .2s;
}

@media (min-width: 768px) {
  .projects-preview::before {
    background: none;
  }
}

.projects-previews_li .projects-preview {
  transition: transform linear .2s, z-index 0s .2s;
  z-index: auto;
}

@media (min-width: 1001px) {
  .projects-previews_li .projects-preview {
    padding-bottom: 63%;
  }
}

.projects-previews_li:hover .projects-preview {
  z-index: 2;
  transition: transform linear .2s, z-index 0s .2s;
}

@media (min-width: 1001px) {
  .projects-previews_li:hover .projects-preview {
    transform: scale3d(2.07, 2.07, 2.07);
  }
}

.projects-slide .projects-preview:hover::before {
  background-color: transparent;
}

.projects-slide:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
}

.projects-slide .projects-preview::before {
  background-color: rgba(0, 0, 18, 0.6);
  transition: background ease-out 0.4s;
}

@media (min-width: 1001px) {
  .projects-slide .projects-preview_link {
    font-size: 16px;
  }
}

.projects-slide.swiper-slide-active .projects-preview::before, .projects-slide.swiper-slide-duplicate-active .projects-preview::before {
  background-color: rgba(0, 0, 18, 0.35) !important;
}

@media (min-width: 1001px) {
  .projects-slide.swiper-slide-active .projects-preview_link {
    font-size: 16px;
  }
}

.projects-slide.swiper-slide-active:before {
  z-index: -1;
}

.projects-preview.mod_bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projects-preview_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 15px;
}

@media (min-width: 1001px) {
  .projects-preview_container {
    padding-left: 20px;
    padding-right: 20px;
    bottom: 10px;
    opacity: 0;
    transition: opacity linear .2s;
    padding-bottom: 0;
  }
}

.swiper-slide .projects-preview_container {
  opacity: 0;
  transition: opacity ease-out 0.4s;
}

.swiper-slide-active .projects-preview_container,
.swiper-slide-duplicate-active .projects-preview_container,
.projects-previews_li:hover .projects-preview_container {
  opacity: 1;
}

@media (min-width: 1001px) {
  .projects-previews_li .projects-preview_container {
    padding-left: 10px;
    padding-right: 10px;
    bottom: 8px;
  }
}

@media (max-width: 767px) {
  .projects.mod_latest .project-slider_container {
    padding-top: 28px;
  }
}

@media (min-width: 768px) {
  .projects.mod_latest .project-slider_container {
    margin-top: -8px;
  }
}

.projects-preview_title {
  color: inherit;
  font-size: 23px;
  line-height: 1;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .projects-preview_title {
    display: flex;
    align-items: flex-end;
    height: 73px;
    overflow: hidden;
  }
}

@media (min-width: 1001px) {
  .projects-preview_title {
    display: block;
    height: auto;
    font-size: 34px;
  }
}

@media (min-width: 1001px) {
  .projects-previews_li .projects-preview_title {
    font-size: 17px;
  }
}

.projects-preview_description {
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

@media (min-width: 1001px) {
  .projects-preview_description {
    margin-top: 2px;
    font-size: 14px;
  }
}

@media (min-width: 1001px) {
  .projects-previews_li .projects-preview_description {
    margin-bottom: 5px;
    margin-top: 0;
    font-size: 7px;
  }
}

.projects-preview_link {
  font-size: 0;
  color: #f37036;
}

@media (min-width: 1001px) {
  .projects-preview_link {
    display: table;
    font-size: 8px;
    line-height: 1;
    margin-top: 3px;
  }
}

.projects-preview_go-to {
  display: none;
}

@media (min-width: 1001px) {
  .projects-preview_go-to {
    display: inline-block;
  }
}

@media (min-width: 1001px) {
  .projects-previews_li .projects-preview_go-to {
    width: 14px;
    height: 7px;
    margin-left: 1px;
    margin-top: -1px;
  }
}

.page_projects main,
.page_projects-detail main {
  padding-top: 80px;
  overflow: hidden;
  height: auto;
}

@media (min-width: 768px) {
  .page_projects main,
  .page_projects-detail main {
    height: auto;
  }
}

@media (min-width: 1001px) {
  .page_projects main,
  .page_projects-detail main {
    padding-top: 140px;
    padding-bottom: 20px;
  }
}

#project_page {
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  #project_page {
    padding-bottom: 54px;
  }
}

.project_title {
  font-size: 42px;
  line-height: 1.1;
}

@media (min-width: 1001px) {
  .project_title {
    font-size: 54px;
    line-height: 1;
    margin-bottom: 2px;
  }
}

.project_title-description {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 1.8;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media (min-width: 1001px) {
  .project_title-description {
    margin-bottom: 16px;
  }
}

.project_content {
  font-size: 16px;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.project_pagination {
  display: none;
}

@media (min-width: 768px) {
  .project_pagination {
    display: flex;
    margin-top: 40px;
  }
}

.project_top {
  position: relative;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .project_top {
    display: flex;
    margin-top: 40px;
    margin-bottom: 27px;
  }
}

.project_container {
  position: relative;
}

@media (min-width: 768px) {
  .project_container {
    align-self: stretch;
    width: 100%;
  }
}

.project_container .project_alone-slide {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .project_container.mod_left {
    width: 50%;
    flex: 1 50%;
  }
}

@media (min-width: 1001px) {
  .project_container.mod_left {
    width: 70%;
    flex: 1 70%;
  }
}

@media (min-width: 768px) {
  .project_container.mod_right {
    width: 50%;
  }
}

@media (min-width: 1001px) {
  .project_container.mod_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 480px;
    min-height: 600px;
  }
}

.project-slider {
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .project-slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.project-slider_wrapper {
  height: 100%;
  display: flex;
}

.project-slide {
  min-height: 100%;
  height: 100%;
  padding-bottom: 80%;
}

@media (min-width: 768px) {
  .project-slide {
    padding-bottom: 0;
  }
}

.project_description-container {
  background-color: #0d0d27;
  color: #ffffff;
}

.project_description {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .project_description {
    width: 480px;
    max-width: 100%;
    padding: 40px;
  }
}

@media (min-width: 768px) {
  .page_support {
    height: 100%;
  }
}

.page_support .header {
  background: rgba(0, 0, 0, 0.7);
}

.support-screen-1 {
  position: relative;
  height: 450px;
}

@media (min-width: 768px) {
  .support-screen-1 {
    height: 100%;
  }
}

.support-screen-1 .support_content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  padding: 20px 20px 60px 20px;
}

@media (min-width: 1001px) {
  .support-screen-1 .support_content {
    padding: 40px 40px 8vh 40px;
  }
}

.support-screen-1 .support_content .title {
  margin-bottom: 2px;
}

.support-screen-1 .support_content .description {
  margin: 10px 0 15px 0;
}

@media (min-width: 1001px) {
  .support-screen-1 .support_content .description {
    margin: 8px 0 20px 0;
  }
}

@media (min-width: 1440px) {
  .support-screen-1 .support_content .description {
    margin: 0 0 20px 0;
  }
}

.support-screen-1 .arrow {
  display: none;
}

@media (min-width: 768px) {
  .support-screen-1 .arrow {
    display: block;
  }
}

.support-screen-2 .support_content {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
}

@media (min-width: 768px) {
  .support-screen-2 .support_content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

.support-screen-2 .left-content {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .support-screen-2 .left-content {
    flex: 1 50%;
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-right: 20px;
    margin-bottom: 0;
  }
}

@media (min-width: 1001px) {
  .support-screen-2 .left-content {
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .support-screen-2 .right-content {
    flex: 1 50%;
    width: 50%;
    padding-left: 20px;
  }
}

@media (min-width: 1001px) {
  .support-screen-2 .right-content {
    padding-left: 40px;
  }
}

.support-screen-2 .support_title {
  font-size: 24px;
  color: #f37036;
  margin-bottom: 15px;
}

@media (min-width: 1001px) {
  .support-screen-2 .support_title {
    font-size: 44px;
  }
}

@media (min-width: 1440px) {
  .support-screen-2 .support_title {
    font-size: 48px;
  }
}

.support-screen-2 .support_title.mod_sup_color {
  color: #282828;
}

.support-screen-2 .support_form-content {
  position: relative;
  width: 100%;
  max-width: 575px;
}

.support-screen-2 .support_form .order_form {
  max-width: 100%;
  padding: 0;
}

.support-screen-2 .support_form .order_field {
  border-bottom: 2px solid #d4cfcd;
}

.support-screen-2 .support_form .order_field.active {
  border-bottom: 2px solid #282828;
}

.support-screen-2 .support_form .order_input {
  color: #282828;
}

.support-screen-2 .support_form .order_label {
  color: #464646;
}

.support-screen-2 .support_form .order_form-button {
  width: 100%;
  margin-top: 15px;
}

.support-screen-2 .support_accordion-item.item {
  margin-bottom: 20px;
}

.support-screen-2 .support_accordion-item.item .item_title {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  font-size: 20px;
  color: #232323;
}

.support-screen-2 .support_accordion-item.item .item_title .icon {
  position: relative;
  top: -3px;
  right: -6px;
  width: 8px;
  height: 8px;
  background: url("/img/plus.svg");
}

.support-screen-2 .support_accordion-item.item .item_title.active .icon {
  transform: rotateZ(45deg);
}

.support-screen-2 .support_accordion-item.item .item_description {
  font-size: 16px;
  color: #434343;
  padding-top: 10px;
  padding-left: 10px;
}

@media (min-width: 1001px) {
  .support-screen-2 .support_accordion-item.item .item_description {
    padding-top: 20px;
    padding-left: 20px;
  }
}

.equipment-preview.item {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #282828;
  height: 275px;
  border: 2px solid #f8f3f1;
}

@media (min-width: 1001px) {
  .equipment-preview.item {
    border: 2px solid #f8f3f1;
    height: 265px;
  }
  .equipment-preview.item:hover {
    top: -10px;
    border: 2px solid #f37036;
    box-shadow: 0 20px 30px 0 rgba(29, 29, 27, 0.15);
  }
  .equipment-preview.item:hover .item_go {
    top: 70%;
    opacity: 1;
  }
  .equipment-preview.item:hover .item_title {
    top: -15px;
  }
}

@media (min-width: 1440px) {
  .equipment-preview.item {
    height: 315px;
  }
}

@media (min-width: 1920px) {
  .equipment-preview.item {
    height: 420px;
  }
}

.equipment-preview .item_block {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.equipment-preview .item_block:nth-child(1) {
  flex: 1;
}

.equipment-preview .item_block:nth-child(2) {
  height: 70px;
}

@media (min-width: 1001px) {
  .equipment-preview .item_block:nth-child(2) {
    height: 100px;
  }
}

.equipment-preview .item_image-container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 28px;
}

@media (min-width: 1001px) {
  .equipment-preview .item_image-container {
    top: 20px;
  }
}

@media (min-width: 1440px) {
  .equipment-preview .item_image-container {
    top: 25px;
  }
}

.equipment-preview .item_title {
  position: relative;
  top: 0;
  text-align: center;
  font-size: 20px;
}

.equipment-preview .item_go {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  opacity: 0;
  display: none;
}

@media (min-width: 768px) {
  .equipment-preview .item_go {
    display: flex;
    width: 100%;
  }
}

.equipment_list {
  padding-top: 30px;
}

@media (min-width: 1001px) {
  .equipment_list {
    display: flex;
    align-items: flex-start;
  }
}

.equipment_list .section-left {
  margin-bottom: 40px;
}

@media (min-width: 1001px) {
  .equipment_list .section-left {
    position: fixed;
    z-index: 2;
    left: 40px;
    top: 205px;
    width: 375px;
    max-width: 375px;
    min-width: 375px;
    padding-right: 40px;
    margin-bottom: 0;
  }
}

.equipment_list .equipment_list-content {
  font-size: 0;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 25px;
}

@media (min-width: 1440px) {
  .equipment_list .equipment_list-content {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.equipment_list .equipment_item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 10px;
  margin-bottom: 25px;
}

@media (min-width: 1280px) {
  .equipment_list .equipment_item {
    width: 33.33%;
  }
}

@media (min-width: 1440px) {
  .equipment_list .equipment_item {
    padding: 0 15px;
  }
}

.equipment_list .equipment_item .equipment-preview {
  height: 200px;
}

@media (min-width: 768px) {
  .equipment_list .equipment_item .equipment-preview {
    height: 275px;
  }
}

@media (min-width: 1001px) {
  .equipment_list .equipment_item .equipment-preview {
    height: 265px;
  }
}

@media (min-width: 1440px) {
  .equipment_list .equipment_item .equipment-preview {
    height: 315px;
  }
}

.equipment_list .equipment_item .equipment-preview .item_title {
  font-size: 15px;
}

@media (min-width: 768px) {
  .equipment_list .equipment_item .equipment-preview .item_title {
    font-size: 20px;
  }
}

.equipment_list .equipment_item .equipment-preview .item_image-container {
  top: 0;
}

@media (min-width: 768px) {
  .equipment_list .equipment_item .equipment-preview .item_image-container {
    top: 28px;
    background-size: contain;
  }
}

.equipment_list .section-right {
  position: relative;
}

@media (min-width: 1001px) {
  .equipment_list .section-right {
    padding-left: 375px;
    padding-top: 35px;
    flex: 1 100%;
  }
}

.equipment_list .equipment_list-block {
  margin-bottom: 25px;
}

.equipment_list .left_menu {
  padding-top: 20px;
}

@media (min-width: 1001px) {
  .page_equipment .Breadcrumbs {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 140px;
  }
}

.equipment_all {
  width: 225px;
  margin: 40px auto 0;
}

.page_equipment-detail .Breadcrumbs {
  margin-bottom: 50px;
}

.equipment_detail .detail {
  display: block;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1001px) {
  .equipment_detail .detail {
    display: flex;
    align-items: flex-start;
  }
}

.equipment_detail .equipment_detail-swiper {
  height: 100%;
  width: 100%;
}

.equipment_detail .equipment_detail-swiper .swiper-slide {
  height: 100%;
}

.equipment_detail .equipment_detail-swiper .swiper-slide .detail_image {
  right: 0;
}

@media (min-width: 1001px) {
  .equipment_detail .equipment_detail-pagination {
    padding-right: 50px;
  }
}

.equipment_detail .equipment_detail-pagination.swiper-pagination-bullets {
  bottom: 0;
}

.equipment_detail .equipment-swiper {
  margin-top: 35px;
}

.equipment_detail .section-left {
  position: relative;
  height: 300px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

@media (min-width: 1001px) {
  .equipment_detail .section-left {
    flex: 1 100%;
    width: -webkit-calc(100% - 500px);
    width: -moz-calc(100% - 500px);
    width: calc(100% - 500px);
    max-width: calc(100% - 500px);
    height: 480px;
    padding-right: 50px;
    margin-bottom: 0;
  }
}

.equipment_detail .detail_image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .equipment_detail .detail_image {
    right: 50px;
  }
}

.equipment_detail .detail_image.alone-image {
  background-size: contain;
}

.equipment_detail .section-right {
  height: 100%;
}

@media (min-width: 1001px) {
  .equipment_detail .section-right {
    flex: 1 500px;
    width: 500px;
    max-width: 500px;
    min-width: 500px;
    margin-bottom: 0;
  }
}

.equipment_detail .characteristic_item {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .equipment_detail .characteristic_item {
    margin-bottom: 0;
  }
}

.equipment_detail .characteristic_content {
  font-size: 14px;
  color: #696969;
}

@media (min-width: 768px) {
  .equipment_detail .characteristic_content {
    display: flex;
    align-items: flex-start;
  }
}

.equipment_detail .characteristic_title {
  position: relative;
  margin-right: 10px;
  color: #282828;
}

@media (min-width: 768px) {
  .equipment_detail .characteristic_title {
    flex: 1 350px;
    min-width: 350px;
    max-width: 350px;
    color: #696969;
  }
}

@media (min-width: 1440px) {
  .equipment_detail .characteristic_title {
    flex: 1 265px;
    min-width: 255px;
    max-width: 255px;
  }
}

@media (min-width: 768px) {
  .equipment_detail .characteristic_title:before {
    content: ".";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 2px;
    background-image: url("/img/dot.png");
    background-size: contain;
    background-repeat: repeat-x;
  }
}

.equipment_detail .characteristic_title span {
  position: relative;
  background: #fff;
  padding-right: 3px;
}

@media (min-width: 768px) {
  .equipment_detail .characteristic_value {
    flex: 1 100%;
  }
}

.equipment_detail .detail_navigation {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.equipment_detail .detail_navigation li {
  color: #959595;
  font-size: 16px;
  padding: 5px 5px 5px 0;
  margin-right: 15px;
  cursor: pointer;
}

@media (min-width: 1001px) {
  .equipment_detail .detail_navigation li:hover {
    color: #f37036;
  }
}

.equipment_detail .detail_navigation li.Active {
  color: #f37036;
}

.equipment_detail .detail_description {
  display: none;
  font-size: 14px;
  color: #696969;
}

.equipment_detail .detail_characteristic {
  display: none;
}

.equipment_detail .detail_info {
  margin-bottom: 25px;
}

.page_equipment-detail main {
  margin-top: 80px;
  margin-bottom: 50px;
  overflow: hidden;
  height: auto;
}

@media (min-width: 1001px) {
  .page_equipment-detail main {
    margin-top: 140px;
    margin-bottom: 65px;
  }
}

.page_equipment main {
  padding-top: 80px;
  padding-bottom: 25px;
  overflow: hidden;
  height: auto;
}

@media (min-width: 1001px) {
  .page_equipment main {
    padding-top: 140px;
    padding-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .page_contacts {
    height: 100%;
  }
}

.page_contacts .container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.page_contacts .header.md_back-blue {
  background: #000012;
}

.page_contacts main {
  position: relative;
  height: auto;
}

@media (min-width: 768px) {
  .page_contacts main {
    flex: 1 100%;
    height: 100%;
  }
}

.page_contacts .contacts {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 500px;
  padding-top: 80px;
}

@media (min-width: 768px) {
  .page_contacts .contacts {
    position: absolute;
    height: 100%;
  }
}

@media (min-width: 1001px) {
  .page_contacts .contacts {
    padding-top: 125px;
  }
}

.page_contacts .contacts_info {
  position: absolute;
  z-index: 1;
  top: 80px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000012;
  color: #fff;
  padding: 10px 20px;
}

@media (min-width: 1001px) {
  .page_contacts .contacts_info {
    top: 125px;
    padding: 10px 40px;
  }
}

.page_contacts .contacts_info > * {
  width: 50%;
}

.page_contacts .contacts_info > *:nth-child(1) {
  text-align: left;
}

.page_contacts .contacts_info > *:nth-child(2) {
  text-align: right;
}

.page_contacts .contacts_info span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.page_motion-design {
  height: 100%;
}

.page_motion-design .header {
  background: none;
}

.motion-design {
  position: relative;
  background: #000012;
}

@media (min-width: 768px) {
  .motion-design {
    height: 100%;
  }
}

.motion-design.md_color-light {
  color: #f8f3f1;
}

.motion-design .section_content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  background-image: url("/img/motion-design-back.jpg");
  background-size: cover;
  background-position: center top;
}

.motion-design .section_titles {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 80px 20px 40px 20px;
}

@media (min-width: 768px) {
  .motion-design .section_titles {
    height: auto;
    justify-content: flex-end;
    padding: 80px 20px 0 20px;
  }
}

@media (min-width: 1001px) {
  .motion-design .section_titles {
    padding: 140px 80px 0 40px;
  }
}

.motion-design .section_logo {
  display: block;
  max-width: 170px;
  width: 100%;
  height: auto;
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .motion-design .section_logo {
    max-width: 200px;
  }
}

@media (min-width: 1001px) {
  .motion-design .section_logo {
    max-width: 310px;
  }
}

.motion-design .section_description {
  margin: 5px 0 20px 0;
}

.motion-design .section_description:before {
  background: #f8f3f1;
}

.motion-design .section_button {
  font-size: 0;
}

.motion-design .section_link {
  color: #f8f3f1;
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  border: 2px solid #f37036;
  padding: 12px 56px;
  cursor: pointer;
  background: none;
  margin-left: 30px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .motion-design .section_link:hover {
    background: #f37036;
  }
  .motion-design .section_link:hover span {
    left: -20px;
    transition: all linear 0.2s;
  }
  .motion-design .section_link:hover svg {
    right: -35px;
    opacity: 1;
    transition: all linear 0.2s;
  }
}

.motion-design .section_link span {
  font-family: 'BebasNeue';
  font-weight: 700;
  position: relative;
  left: 0;
  line-height: 15px;
  padding-top: 2px;
}

.motion-design .section_link svg {
  position: absolute;
  right: 0;
  top: 1px;
  display: none;
  width: 25px;
  height: 15px;
  fill: #f8f3f1;
  opacity: 0;
}

@media (min-width: 768px) {
  .motion-design .section_link svg {
    display: block;
  }
}

@media (min-width: 768px) {
  .motion-design .section_link {
    margin-bottom: 0;
  }
}

@media (min-width: 1001px) {
  .motion-design .section_link {
    margin-left: 45px;
  }
}

.motion-design .section_link.md_second {
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  border: 2px solid #f37036;
  padding: 12px 44px;
  cursor: pointer;
  background: none;
}

@media (min-width: 768px) {
  .motion-design .section_link.md_second:hover {
    background: #f37036;
  }
  .motion-design .section_link.md_second:hover span {
    left: -20px;
    transition: all linear 0.2s;
  }
  .motion-design .section_link.md_second:hover svg {
    right: -35px;
    opacity: 1;
    transition: all linear 0.2s;
  }
}

.motion-design .section_link.md_second span {
  font-family: 'BebasNeue';
  font-weight: 700;
  position: relative;
  left: 0;
  line-height: 15px;
  padding-top: 2px;
}

.motion-design .section_link.md_second svg {
  position: absolute;
  right: 0;
  top: 1px;
  display: none;
  width: 25px;
  height: 15px;
  fill: #f8f3f1;
  opacity: 0;
}

@media (min-width: 768px) {
  .motion-design .section_link.md_second svg {
    display: block;
  }
}

@media (min-width: 768px) {
  .motion-design .section_link.md_second {
    margin-left: 26px;
  }
}

.page_invitation {
  height: 100%;
}

.page_invitation .header {
  background: none;
}

.page_invitation main {
  height: 100%;
}

#invitation_map {
  max-width: 462px;
  width: 100%;
  height: 250px;
}

#invitation_map .gm-style > div:nth-child(2), #invitation_map .gm-style > div:nth-child(4), #invitation_map .gm-style > div:nth-child(6),
#invitation_map .gm-style > div:nth-child(8), #invitation_map .gm-style > div:nth-child(10) {
  display: none;
}

.invitation {
  position: relative;
}

@media (min-width: 768px) {
  .invitation {
    height: 100%;
  }
}

.invitation .invitation_map-wrap {
  margin-bottom: 26px;
}

@media (min-width: 1200px) {
  .invitation .invitation_map-wrap {
    padding-left: 45px;
  }
}

.invitation .section_content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  background: #000012;
}

.invitation .section_titles {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 1440px;
  padding: 100px 20px 20px 20px;
}

@media (min-width: 768px) {
  .invitation .section_titles {
    height: auto;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@media (min-width: 1001px) {
  .invitation .section_titles {
    padding: 160px 40px 0 40px;
  }
}

@media (min-width: 1200px) {
  .invitation .section_titles {
    flex-direction: row;
  }
}

.invitation .section_block {
  width: 100%;
  margin-bottom: 20px;
}

.invitation .invitation_contacts {
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .invitation .invitation_contacts {
    padding-left: 45px;
  }
}

.invitation .invitation_contact .contact_content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  font-size: 12px;
  line-height: 15px;
}

@media (min-width: 1001px) {
  .invitation .invitation_contact .contact_content {
    font-size: 16px;
    line-height: 22px;
  }
}

.invitation .invitation_contact .contact_content a {
  display: inline;
  line-height: normal;
}

.invitation .invitation_contact dt {
  color: #f37036;
  font-weight: 700;
  min-width: 55px;
}

@media (min-width: 375px) {
  .invitation .invitation_contact dt {
    min-width: 0;
  }
}

.invitation .invitation_contact dd {
  color: #f8f3f1;
  margin-left: 5px;
}

.invitation .invitation_description {
  font-size: 15px;
  color: #f8f3f1;
  padding-top: 10px;
}

@media (min-width: 1200px) {
  .invitation .invitation_description {
    width: 688px;
    min-width: 688px;
    max-width: 688px;
  }
}

.invitation .invitation_description > *:not(:last-child) {
  margin-bottom: 20px;
}

.invitation .invitation_description .full_str {
  display: block;
}

.invitation .invitation_description .title {
  font-size: 16px;
}

.invitation .invitation_description .title span {
  display: block;
  text-transform: uppercase;
}

.invitation .section_description {
  margin: 5px 0 20px 0;
}

.invitation .section_description:before {
  background: #f8f3f1;
}

.invitation .section_button {
  font-size: 0;
}

@media (min-width: 1200px) {
  .invitation .section_button {
    margin-left: 45px;
  }
}

.invitation .section_link {
  color: #f8f3f1;
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  border: 2px solid #f37036;
  padding: 12px 53px;
  cursor: pointer;
  background: none;
  margin-bottom: 25px;
  margin-right: 18px;
}

@media (min-width: 768px) {
  .invitation .section_link:hover {
    background: #f37036;
  }
  .invitation .section_link:hover span {
    left: -20px;
    transition: all linear 0.2s;
  }
  .invitation .section_link:hover svg {
    right: -35px;
    opacity: 1;
    transition: all linear 0.2s;
  }
}

.invitation .section_link span {
  font-family: 'BebasNeue';
  font-weight: 700;
  position: relative;
  left: 0;
  line-height: 15px;
  padding-top: 2px;
}

.invitation .section_link svg {
  position: absolute;
  right: 0;
  top: 1px;
  display: none;
  width: 25px;
  height: 15px;
  fill: #f8f3f1;
  opacity: 0;
}

@media (min-width: 768px) {
  .invitation .section_link svg {
    display: block;
  }
}

.invitation .section_link.md_second {
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  border: 2px solid #f37036;
  padding: 12px 44px;
  cursor: pointer;
  background: none;
}

@media (min-width: 768px) {
  .invitation .section_link.md_second:hover {
    background: #f37036;
  }
  .invitation .section_link.md_second:hover span {
    left: -20px;
    transition: all linear 0.2s;
  }
  .invitation .section_link.md_second:hover svg {
    right: -35px;
    opacity: 1;
    transition: all linear 0.2s;
  }
}

.invitation .section_link.md_second span {
  font-family: 'BebasNeue';
  font-weight: 700;
  position: relative;
  left: 0;
  line-height: 15px;
  padding-top: 2px;
}

.invitation .section_link.md_second svg {
  position: absolute;
  right: 0;
  top: 1px;
  display: none;
  width: 25px;
  height: 15px;
  fill: #f8f3f1;
  opacity: 0;
}

@media (min-width: 768px) {
  .invitation .section_link.md_second svg {
    display: block;
  }
}

.xdsoft_datetimepicker {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
  background: #fff;
  border-bottom: 1px solid #bbb;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 8px;
  padding-left: 0;
  padding-top: 2px;
  position: absolute;
  z-index: 9999;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
}

.xdsoft_datetimepicker.xdsoft_rtl {
  padding: 8px 0 8px 8px;
}

.xdsoft_datetimepicker iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 75px;
  height: 210px;
  background: transparent;
  border: 0;
}

.xdsoft_datetimepicker button {
  border: none !important;
}

.xdsoft_noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.xdsoft_noselect::selection {
  background: transparent;
}

.xdsoft_noselect::-moz-selection {
  background: transparent;
}

.xdsoft_datetimepicker.xdsoft_inline {
  display: inline-block;
  position: static;
  box-shadow: none;
}

.xdsoft_datetimepicker * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
  display: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
  display: block;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
  width: 224px;
  float: left;
  margin-left: 8px;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
  float: right;
  margin-right: 8px;
  margin-left: 0;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
  width: 256px;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
  width: 58px;
  float: left;
  text-align: center;
  margin-left: 8px;
  margin-top: 0;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker {
  float: right;
  margin-right: 8px;
  margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active + .xdsoft_timepicker {
  margin-top: 8px;
  margin-bottom: 3px;
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
  position: relative;
  text-align: center;
}

.xdsoft_datetimepicker .xdsoft_label i, .xdsoft_datetimepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_next, .xdsoft_datetimepicker .xdsoft_today_button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC);
}

.xdsoft_datetimepicker .xdsoft_label i {
  opacity: .5;
  background-position: -92px -19px;
  display: inline-block;
  width: 9px;
  height: 20px;
  vertical-align: middle;
}

.xdsoft_datetimepicker .xdsoft_prev {
  float: left;
  background-position: -20px 0;
}

.xdsoft_datetimepicker .xdsoft_today_button {
  float: left;
  background-position: -70px 0;
  margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_next {
  float: right;
  background-position: 0 0;
}

.xdsoft_datetimepicker .xdsoft_next, .xdsoft_datetimepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_today_button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0 none;
  cursor: pointer;
  display: block;
  height: 30px;
  opacity: .5;
  -ms-filter: "alpha(opacity=50)";
  outline: medium none;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: 100%;
  white-space: nowrap;
  width: 20px;
  min-width: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
  float: none;
  background-position: -40px -15px;
  height: 15px;
  width: 30px;
  display: block;
  margin-left: 14px;
  margin-top: 7px;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_prev, .xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_next {
  float: none;
  margin-left: 0;
  margin-right: 14px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
  background-position: -40px 0;
  margin-bottom: 7px;
  margin-top: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
  height: 151px;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div {
  background: #f5f5f5;
  border-top: 1px solid #ddd;
  color: #666;
  font-size: 12px;
  text-align: center;
  border-collapse: collapse;
  cursor: pointer;
  border-bottom-width: 0;
  height: 25px;
  line-height: 25px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:first-child {
  border-top-width: 0;
}

.xdsoft_datetimepicker .xdsoft_today_button:hover, .xdsoft_datetimepicker .xdsoft_next:hover, .xdsoft_datetimepicker .xdsoft_prev:hover {
  opacity: 1;
  -ms-filter: "alpha(opacity=100)";
}

.xdsoft_datetimepicker .xdsoft_label {
  display: inline;
  position: relative;
  z-index: 9999;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background-color: #fff;
  float: left;
  width: 182px;
  text-align: center;
  cursor: pointer;
}

.xdsoft_datetimepicker .xdsoft_label:hover > span {
  text-decoration: underline;
}

.xdsoft_datetimepicker .xdsoft_label:hover i {
  opacity: 1.0;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
  border: 1px solid #ccc;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 101;
  display: none;
  background: #fff;
  max-height: 160px;
  overflow-y: hidden;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect {
  right: -7px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect {
  right: 2px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  color: #fff;
  background: #ff8000;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
  padding: 2px 10px 2px 5px;
  text-decoration: none !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background: #3af;
  box-shadow: #178fe5 0 1px 3px 0 inset;
  color: #fff;
  font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_month {
  width: 100px;
  text-align: right;
}

.xdsoft_datetimepicker .xdsoft_calendar {
  clear: both;
}

.xdsoft_datetimepicker .xdsoft_year {
  width: 48px;
  margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
  border-collapse: collapse;
  width: 100%;
}

.xdsoft_datetimepicker .xdsoft_calendar td > div {
  padding-right: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
  height: 25px;
}

.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th {
  width: 14.2857142%;
  background: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
  font-size: 12px;
  text-align: right;
  vertical-align: middle;
  padding: 0;
  border-collapse: collapse;
  cursor: pointer;
  height: 25px;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
  width: 12.5%;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
  background: #f1f1f1;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
  color: #3af;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_default {
  background: #ffe9d2;
  box-shadow: #ffb871 0 1px 4px 0 inset;
  color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_mint {
  background: #c1ffc9;
  box-shadow: #00dd1c 0 1px 4px 0 inset;
  color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #fff !important;
  background: #f37036 !important;
  font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled, .xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
  opacity: .5;
  -ms-filter: "alpha(opacity=50)";
  cursor: default;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
  opacity: .2;
  -ms-filter: "alpha(opacity=20)";
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #fff !important;
  background: #f37036 !important;
  box-shadow: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current.xdsoft_disabled:hover {
  background: #f37036 !important;
  color: #fff !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_disabled:hover {
  color: inherit !important;
  background: inherit !important;
  box-shadow: inherit !important;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
  font-weight: 700;
  text-align: center;
  color: #999;
  cursor: default;
}

.xdsoft_datetimepicker .xdsoft_copyright {
  color: #ccc !important;
  font-size: 10px;
  clear: both;
  float: none;
  margin-left: 8px;
}

.xdsoft_datetimepicker .xdsoft_copyright a {
  color: #eee !important;
}

.xdsoft_datetimepicker .xdsoft_copyright a:hover {
  color: #aaa !important;
}

.xdsoft_time_box {
  position: relative;
  border: 1px solid #ccc;
}

.xdsoft_scrollbar > .xdsoft_scroller {
  background: #ccc !important;
  height: 20px;
  border-radius: 3px;
}

.xdsoft_scrollbar {
  position: absolute;
  width: 7px;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_scrollbar {
  left: 0;
  right: auto;
}

.xdsoft_scroller_box {
  position: relative;
}

.xdsoft_datetimepicker.xdsoft_dark {
  box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
  background: #000;
  border-bottom: 1px solid #444;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-top: 1px solid #333;
  color: #ccc;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box {
  border-bottom: 1px solid #222;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div {
  background: #0a0a0a;
  border-top: 1px solid #222;
  color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label {
  background-color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select {
  border: 1px solid #333;
  background: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  color: #000;
  background: #007fff;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background: #c50;
  box-shadow: #b03e00 0 1px 3px 0 inset;
  color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label i, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_prev, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_next, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_today_button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUExQUUzOTA0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUExQUUzOTE0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQTFBRTM4RTQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQTFBRTM4RjQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp0VxGEAAAIASURBVHja7JrNSgMxEMebtgh+3MSLr1T1Xn2CHoSKB08+QmR8Bx9A8e7RixdB9CKCoNdexIugxFlJa7rNZneTbLIpM/CnNLsdMvNjM8l0mRCiQ9Ye61IKCAgZAUnH+mU3MMZaHYChBnJUDzWOFZdVfc5+ZFLbrWDeXPwbxIqrLLfaeS0hEBVGIRQCEiZoHQwtlGSByCCdYBl8g8egTTAWoKQMRBRBcZxYlhzhKegqMOageErsCHVkk3hXIFooDgHB1KkHIHVgzKB4ADJQ/A1jAFmAYhkQqA5TOBtocrKrgXwQA8gcFIuAIO8sQSA7hidvPwaQGZSaAYHOUWJABhWWw2EMIH9QagQERU4SArJXo0ZZL18uvaxejXt/Em8xjVBXmvFr1KVm/AJ10tRe2XnraNqaJvKE3KHuUbfK1E+VHB0q40/y3sdQSxY4FHWeKJCunP8UyDdqJZenT3ntVV5jIYCAh20vT7ioP8tpf6E2lfEMwERe+whV1MHjwZB7PBiCxcGQWwKZKD62lfGNnP/1poFAA60T7rF1UgcKd2id3KDeUS+oLWV8DfWAepOfq00CgQabi9zjcgJVYVD7PVzQUAUGAQkbNJTBICDhgwYTjDYD6XeW08ZKh+A4pYkzenOxXUbvZcWz7E8ykRMnIHGX1XPl+1m2vPYpL+2qdb8CDAARlKFEz/ZVkAAAAABJRU5ErkJggg==);
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  background: #0a0a0a;
  border: 1px solid #222;
  color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  background: #0e0e0e;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_today {
  color: #c50;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_default {
  background: #ffe9d2;
  box-shadow: #ffb871 0 1px 4px 0 inset;
  color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_mint {
  background: #c1ffc9;
  box-shadow: #00dd1c 0 1px 4px 0 inset;
  color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  background: #c50;
  box-shadow: #b03e00 0 1px 3px 0 inset;
  color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td:hover, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000 !important;
  background: #007fff !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  color: #666;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright {
  color: #333 !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a {
  color: #111 !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a:hover {
  color: #555 !important;
}

.xdsoft_dark .xdsoft_time_box {
  border: 1px solid #333;
}

.xdsoft_dark .xdsoft_scrollbar > .xdsoft_scroller {
  background: #333 !important;
}

.xdsoft_datetimepicker .xdsoft_save_selected {
  display: block;
  border: 1px solid #ddd !important;
  margin-top: 5px;
  width: 100%;
  color: #454551;
  font-size: 13px;
}

.xdsoft_datetimepicker .blue-gradient-button {
  font-family: "museo-sans", "Book Antiqua", sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #82878c;
  height: 28px;
  position: relative;
  padding: 4px 17px 4px 33px;
  border: 1px solid #d7d8da;
  background: -moz-linear-gradient(top, #fff 0, #f4f8fa 73%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(73%, #f4f8fa));
  background: -webkit-linear-gradient(top, #fff 0, #f4f8fa 73%);
  background: -o-linear-gradient(top, #fff 0, #f4f8fa 73%);
  background: -ms-linear-gradient(top, #fff 0, #f4f8fa 73%);
  background: linear-gradient(to bottom, #fff 0, #f4f8fa 73%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#f4f8fa', GradientType=0);
}

.xdsoft_datetimepicker .blue-gradient-button:hover, .xdsoft_datetimepicker .blue-gradient-button:focus, .xdsoft_datetimepicker .blue-gradient-button:hover span, .xdsoft_datetimepicker .blue-gradient-button:focus span {
  color: #ааа;
  background: #f37036 !important;
}
