//.projects-preview/
.projects-preview
{
    @extend %backlayer;
    position:       relative;
    padding-bottom: 56%;
    color:          #ffffff;

    //.projects-preview:hover
    &:hover
    {
        //.projects-preview:hover::before
        &::before
        {
            @include media($tu)
            {
                background-color: rgba($blue, .45);
            }

        }

    }
    &::before
    {
        content:          '';
        position:         absolute;
        top:              0;
        left:             0;
        right:            0;
        bottom:           0;
        background-color: rgba($blue, .45);

        transition:       background linear .2s;
        @include media($tu)
        {
            background: none;
        }
    }

    @at-root .projects-previews_li .projects-preview
    {
        transition: transform linear .2s, z-index 0s .2s;
        z-index:    auto;

        @include media($du)
        {
            padding-bottom: 63%;

        }

        @at-root .projects-previews_li:hover
        {
            .projects-preview
            {
                z-index:    2;
                transition: transform linear .2s, z-index 0s .2s;

                @include media($du)
                {
                    transform: scale3d(2.07, 2.07, 2.07);

                }
            }

        }
    }
    @at-root .projects-slide .projects-preview:hover
    {

        &::before
        {
            background-color: transparent;
        }
    }

    @at-root .projects-slide
    {
        &:before
        {
            content:  "";
            position: absolute;
            top:      0;
            right:    0;
            left:     0;
            bottom:   0;

            z-index:  9;
        }

        .projects-preview::before
        {
            background-color: rgba($blue, 0.6);
            transition:       background $transition-std;
        }

        .projects-preview_link
        {
            @extend %std-transition;

            @include media('desctop-grid')
            {
                font-size: 16px;
            }

        }
    }

    //.projects-slide.swiper-slide-active:hover .projects-preview::before, .projects-slide.swiper-slide-duplicate-active::before
    @at-root .projects-slide.swiper-slide-active .projects-preview::before, .projects-slide.swiper-slide-duplicate-active .projects-preview::before
    {
        background-color: rgba($blue, .35) !important;
    }

    @at-root .projects-slide.swiper-slide-active
    {

        .projects-preview_link
        {
            @extend %std-transition;

            @include media('desctop-grid')
            {
                font-size: 16px;
            }

        }

        &:before
        {
            z-index: -1;
        }
    }

    //.projects-preview.mod_bg/
    &.mod_bg
    {
        background-size:     cover;
        background-position: center;
        background-repeat:   no-repeat;
    }

    //.projects-preview_container/
    &_container
    {
        position:        absolute;
        left:            0;
        right:           0;
        top:             0;
        bottom:          0;

        display:         flex;
        flex-direction:  column;
        justify-content: flex-end;

        padding-left:    $gutter-width / 2 + 4;
        padding-right:   $gutter-width / 2 + 4;

        padding-bottom:  15px;

        @include media($du)
        {
            @include grid-padding();
            bottom:         10px;
            opacity:        0;
            transition:     opacity linear .2s;

            padding-bottom: 0;
        }

        @at-root .swiper-slide .projects-preview_container
        {
            opacity:    0;
            transition: opacity $transition-std;
        }

        @at-root .swiper-slide-active .projects-preview_container,
        .swiper-slide-duplicate-active .projects-preview_container,
        .projects-previews_li:hover .projects-preview_container
        {
            opacity: 1;
        }
        @at-root .projects-previews_li .projects-preview_container
        {
            @include media($du)
            {
                padding-left:  10px;
                padding-right: 10px;
                bottom:        8px;

            }

        }
        @at-root .projects.mod_latest .project-slider_container
        {
            @include media($td)
            {
                padding-top: 28px;
            }

            @include media($tu)
            {
                margin-top: -8px;

            }
        }
    }

    //.projects-preview_title/
    &_title
    {
        color:          inherit;
        font-size:      23px;
        line-height:    1;
        text-transform: uppercase;

        @include media($tu)
        {
            display:     flex;
            align-items: flex-end;
            height:      73px;
            overflow:    hidden;
        }

        @include media($du)
        {
            display:   block;
            height:    auto;
            font-size: 34px;
        }

        @at-root .projects-previews_li .projects-preview_title
        {

            @include media($du)
            {
                font-size: 17px;
            }
        }
    }
    //.projects-preview_description/
    &_description
    {
        font-size:   10px;
        font-family: $font-secondary;
        font-weight: 400;

        @include media($du)
        {
            margin-top: 2px;
            font-size:  14px;
        }
        @at-root .projects-previews_li .projects-preview_description
        {
            @include media($du)
            {
                margin-bottom: 5px;
                margin-top:    0;
                font-size:     7px;

            }
        }
    }
    //.projects-preview_link/
    &_link
    {
        font-size: 0;
        color:     $orange;

        @include media('desctop-grid')
        {
            display:     table;
            font-size:   8px;
            line-height: 1;
            margin-top:  3px;
        }

    }
    //.projects-preview_go-to/
    &_go-to
    {
        display: none;
        @include media($du)
        {
            display: inline-block;
        }

        @at-root .projects-previews_li .projects-preview_go-to
        {
            @include media($du)
            {

                width:       14px;
                height:      7px;
                margin-left: 1px;
                margin-top:  -1px;
            }
        }

    }
}