.home-screen-2
{

    position:       relative;

    padding-top:    60px;
    padding-bottom: 60px;

    @include media('t-ipad')
    {
        padding-top:    0;
        padding-bottom: 0;

        height:         100%;
    }

    .section_content
    {
        display:         flex;
        align-items:     flex-start;
        justify-content: center;
        flex-direction:  column;

        height:          100%;
    }

    .section_titles
    {
        @extend %content;

        margin-bottom: 10px;
    }

    .section_title
    {
        @extend %section_title;
    }

    .section_description
    {
        @extend %section_description;

        color:  $gray-dark;

        margin: 5px 0 20px 0;

        & > p
        {
            &:nth-child(2)
            {
                display: none;

                @include media('t-ipad')
                {
                    display: block;
                }
            }
        }

        &:before
        {
            background: $gray-dark;
        }
    }

    .phone_link
    {
        @extend %main-font;

        position:    relative;
        top:         1px;

        color:       $orange;

        font-size:   16px;
        line-height: 15px;

        @include media('desctop-grid')
        {
            font-size:   16px;
            line-height: 22px;
            top:         0;
        }

        @include media('d-wxgap')
        {
            font-size: 20px;

        }
    }

    .arrow
    {

        @extend %arrow-down;

        display: none;

        @include media('t-ipad')
        {
            display: block;
        }
    }

}

