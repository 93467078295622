.page_contacts
{

    @include media('t-ipad')
    {
        height: 100%;
    }
    .container
    {
        display:        flex;
        flex-direction: column;

        width:          100%;

        min-height:     100vh;
    }
    .header
    {
        &.md_back-blue
        {
            background: $blue;
        }
    }

    main
    {
        position: relative;
        height:   auto;

        @include media('t-ipad')
        {
            flex:   1 100%;
            height: 100%;
        }
    }

    .contacts
    {
        position:    relative;
        top:         0;
        right:       0;
        bottom:      0;
        left:        0;

        height:      500px;
        padding-top: 80px;

        @include media('t-ipad')
        {
            position: absolute;

            height:   100%;
        }

        @include media('desctop-grid')
        {
            padding-top: 125px;
        }
    }

    .contacts_info
    {
        position:        absolute;
        z-index:         1;
        top:             80px;
        right:           0;
        left:            0;

        display:         flex;
        justify-content: space-between;
        align-items:     center;
        background:      $blue;
        color:           $white;

        padding:         10px 20px;

        @include media('desctop-grid')
        {
            top:     125px;
            padding: 10px 40px;
        }

        & > *
        {
            width: 50%;

            &:nth-child(1)
            {
                text-align: left;
            }

            &:nth-child(2)
            {
                text-align: right;
            }
        }

        span
        {
            display:        inline-block;
            vertical-align: middle;

            margin-left:    10px;
        }
    }
}
