//--------------------------------------------------------------------------
// Colors
//--------------------------------------------------------------------------

// Black & White
$black: #000000;
$white: #fff;

// Gray

$gray-lightest: #f8f3f1;
$gray-light: #959595;
$gray: #555555;
$gray-middle: #696969;
$gray-dark: #282828;

// Orange

$orange: #f37036;


// Blue

$blue: #000012;
$blue-light: #0d0d27;

//Grid
$gutter-width: 20px;
$grid-width-base: 1440px;
$grid-width: $grid-width-base;

//transitions
$transition-std: ease-out 0.4s;

//fonts
$font-primary : 'BebasNeueBold', sans-serif;
$font-secondary : 'Roboto', sans-serif;

// Other