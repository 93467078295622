.preloader
{

    position:        fixed;
    top:             0;
    left:            0;
    right:           0;
    bottom:          0;
    display:         flex;

    align-items:     center;
    justify-content: center;

    background:      $black;

    z-index:         9999999;

    opacity:         1;
    transition:      all linear 0.5s;



    &.deactive
    {

        opacity:    0;

        transition: all linear 0.5s;
    }

    &.desactive-next
    {
        z-index: -1;
    }

}