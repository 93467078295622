.svg-container
{
    display: none;
}

.container
{
    height: 100%;
}

*
{
    box-sizing: border-box;
    margin:     0;
    padding:    0;
}

.md_fill-light
{
    fill: $gray-lightest;
}