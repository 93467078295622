.page_equipment-detail
{
    .container
    {
        @extend %screen-container;
    }

    .Breadcrumbs
    {
        margin-bottom: 50px;
    }

}

.equipment_detail
{
    @extend %content;

    .detail
    {
        display:       block;
        margin-top:    20px;

        margin-bottom: 40px;

        @include grid-container-variable(1360px);

        @include media('desctop-grid')
        {
            display:     flex;
            align-items: flex-start;

        }
    }

    .equipment_detail-swiper
    {
        height: 100%;
        width:  100%;

        .swiper-slide
        {
            height: 100%;

            .detail_image
            {
                right: 0;
            }
        }

    }

    .equipment_detail-pagination
    {
        @include media('desctop-grid')
        {
            padding-right: 50px;
        }

        &.swiper-pagination-bullets
        {
            bottom: 0;
        }
    }

    .equipment-swiper
    {
        margin-top: 35px;
    }

    .section-left
    {
        position:       relative;
        height:         300px;
        padding-bottom: 30px;
        margin-bottom:  20px;

        @include media('desctop-grid')
        {
            flex:          1 100%;

            width:         -webkit-calc(100% - 500px);
            width:         -moz-calc(100% - 500px);
            width:         calc(100% - 500px);

            max-width:     calc(100% - 500px);

            height:        480px;

            padding-right: 50px;

            margin-bottom: 0;
        }
    }

    .detail_image
    {
        position:            absolute;
        top:                 0;
        bottom:              0;
        left:                0;
        right:               0;

        background-size:     cover;
        background-position: center;
        background-repeat:   no-repeat;

        @include media('t-ipad')
        {
            right: 50px;
        }

        &.alone-image
        {
            background-size: contain;
        }
    }

    .section-right
    {
        height: 100%;

        @include media('desctop-grid')
        {
            flex:          1 500px;
            width:         500px;
            max-width:     500px;
            min-width:     500px;

            margin-bottom: 0;
        }
    }

    .characteristic_item
    {
        margin-bottom: 10px;

        @include media('t-ipad')
        {
            margin-bottom: 0;
        }
    }

    .characteristic_content
    {
        font-size: 14px;
        color:     $gray-middle;

        @include media('t-ipad')
        {
            display:     flex;
            align-items: flex-start;
        }

    }

    .characteristic_title
    {

        position:     relative;

        margin-right: 10px;

        color:        $gray-dark;

        @include media('t-ipad')
        {
            flex:      1 350px;
            min-width: 350px;
            max-width: 350px;

            color:     $gray-middle;
        }

        @include media('d-wxgap')
        {
            flex:      1 265px;
            min-width: 255px;
            max-width: 255px;
        }

        @include media('t-ipad')
        {
            &:before
            {
                content:           ".";
                position:          absolute;
                left:              0;
                right:             0;
                bottom:            4px;

                height:            2px;
                background-image:  url("/img/dot.png");
                background-size:   contain;
                background-repeat: repeat-x;

            }
        }

        span
        {
            position:      relative;
            background:    $white;
            padding-right: 3px;
        }
    }

    .characteristic_value
    {

        @include media('t-ipad')
        {
            flex: 1 100%;
        }
    }

    .detail_navigation
    {
        display:       flex;
        align-items:   center;

        margin-bottom: 10px;

        li
        {
            @extend %std-transition;

            color:        $gray-light;
            font-size:    16px;

            padding:      5px 5px 5px 0;

            margin-right: 15px;

            cursor:       pointer;

            @include media('desctop-grid')
            {
                &:hover
                {
                    color: #f37036;
                }
            }

            &.Active
            {
                color: #f37036;
            }
        }
    }

    .detail_description
    {
        @extend %other-font;

        display:   none;
        font-size: 14px;
        color:     $gray-middle;
    }

    .detail_characteristic
    {
        display: none;
    }

    .detail_info
    {
        margin-bottom: 25px;
    }
}