//Global modificators
.Slider-arrow
{
    @extend %btn-reset-default;
    position:  absolute;
    z-index:   2;
    top:       50%;
    transform: translateY(-50%) scale(1);

    display:   none;

    cursor:    pointer;

    @include media($du)
    {
        display: block;
    }

    &.swiper-arrow-disabled
    {
        cursor: default;
    }
    &.mod_prev
    {

        @include media($du)
        {
            left: -10px;
        }
    }

    &.mod_next
    {
        @include media($du)
        {
            right: -10px;
        }
    }
    &.mod_boxed
    {
        background-color: rgba(13, 13, 39, .4);
        padding:          12px 17px;

        &.mod_prev
        {
            left: 40px;

        }
        &.mod_next
        {
            right: 40px;
        }

    }
}

.Slider-arrow_svg
{
    width:      9px;
    height:     19px;
    transition: fill $transition-std;

    @at-root .Slider-arrow:not(.swiper-arrow-disabled):hover .Slider-arrow_svg
    {

        @include media($du)
        {
            fill: $orange;
        }
    }
    @at-root .Slider-arrow:not(.swiper-arrow-disabled):active .Slider-arrow_svg
    {

        @include media($dd)
        {
            fill: $orange;
        }
    }

}

.Slider_pagination
{
    //width:      100%;
    left:       0;
    right:      0;
    transform:  translateX(-50%);
    text-align: center;

    .swiper-pagination-bullet
    {
        position:         relative;
        width:            9px;
        height:           9px;
        background-color: transparent;
        margin:           0 6px;
        opacity:          1;
        transition:       background $transition-std, border $transition-std, transform $transition-std;

        @include media($du)
        {

        }
        &::before
        {
            content:  '';
            position: absolute;
            top:      -9px;
            left:     -9px;
            width:    22px;
            height:   22px;
            display:  block;
            cursor:   pointer;
        }

    }
    .swiper-pagination-bullet-active
    {
        transform: scale(1.3);

    }
    &.mod_boxed
    {
        bottom:           0 !important;
        padding:          13px 0;
        background-color: rgba(13, 13, 39, .4);

        @include media($tu)
        {
            padding: $gutter-width * 1.5 0;
        }
    }

}

@at-root .Mod_light
{
    .swiper-pagination-bullet-active
    {
        background-color: $blue;
    }

    .Slider-arrow_svg
    {
        fill: $black;
    }

    .swiper-pagination-bullet
    {
        border: 2px solid $blue;
    }
}

@at-root .Mod_dark
{
    .swiper-pagination-bullet-active
    {
        background-color: $white;
    }

    .swiper-pagination-bullet
    {
        border: 2px solid $white;
    }

    .Slider-arrow_svg
    {
        fill: $white;
    }
}