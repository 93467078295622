.project-slider
{
    height: 100%;
    width:  100%;

    @include media($tu)
    {
        position: absolute;
        top:      0;
        right:    0;
        bottom:   0;
        left:     0;

    }
}

.project-slider_wrapper
{
    height:  100%;
    display: flex;
}

.project-slide
{
    @extend %bg-base;
    min-height:     100%;
    height:         100%;
    padding-bottom: 80%;

    @include media($tu)
    {
        padding-bottom: 0;
    }

}