table
{
    background: none !important;

    p
    {
        padding-bottom: 0 !important;
        line-height:    normal !important;
    }
}

tr
{

}

td
{
    border:       1px solid #000 !important;
    background:   none !important;
    padding-left: 5px;
}

th
{

}

thead
{

}

tfoot
{

}