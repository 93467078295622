.mod_list_projects .tab-block
{
    &:nth-child(n):not(:first-of-type)
    {
        display: none;
    }
}

%backlayer
{
    background-color: rgb(13, 13, 39);

}

%project-shadow
{
    box-shadow: 0 7px 13px 0 rgba(29, 29, 27, 0.15);

    @include media($du)
    {
        box-shadow: 0 15px 25px 0 rgba(29, 29, 27, 0.15);
    }
}

//.projects/
.projects
{
    @include grid-container-variable();
    //.projects.mod_completed
    &.mod_completed
    {
        margin-bottom: 30px;

    }
    //.projects.mod_latest
    &.mod_latest
    {

    }

    .tab-block
    {

        .Section_heading
        {

            display: none;

            padding: 0 20px;

            @include media('desctop-grid')
            {
                padding: 0 40px;
            }

            &.active
            {
                display: block;
            }
        }

    }

}

@import "projects/projects-headings",
"projects/projects-slider",
"projects/projects-previews",
"projects/projects-preview";

.page_projects main,
.page_projects-detail main
{
    padding-top: 80px;
    overflow:    hidden;
    height:      auto;
    @include media('t-ipad')
    {
        height: auto;

    }

    @include media($du)
    {
        padding-top:    140px;
        padding-bottom: $gutter-width;
    }
}